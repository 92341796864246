import { useTracking } from '@hooks/tracking';
import { useUser } from '@hooks/user';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AUTH_TOKEN_COOKIE from '@utils/cookies/authCookie';
import cookies from '@utils/cookies';

import { saveOptIn } from '@services/notifications';
import { RatingModal } from '@startse/react-components';

import ShareArea from '@components/core/Share';
import Script from 'next/script';

interface IOneSignalNotification {
  id: string;
  heading: Object;
  contents: Object;
  data: {
    link?: string;
    action?: {
      type: string;
      data: {
        event: string;
        activity: string;
      };
    };
  };
}

const InitOnSignal: React.FC = () => {
  const { user } = useUser();
  const { track } = useTracking();
  const [evaluatingSpeaker, setEvaluatingSpeaker] = useState('');
  const [oneSignalSetup, setOneSignalSetup] = useState({
    hasInstantiedOneSignal: false,
    playerId: '',
  });

  const oneSignalConfig = useMemo(
    () => ({
      appId: process.env.NEXT_PUBLIC_ONESIGNAL_KEY,
      notifyButton: {
        enable: false,
      },
      allowLocalhostAsSecureOrigin: true,
    }),
    [],
  );

  const handleOpenedNotification = useCallback(
    (notification: IOneSignalNotification) => {
      track('Web Push notification Opened', {
        id: notification.id,
        title: notification.heading,
        contents: notification.contents,
      });
      const { link, action } = notification.data;
      if (link) document.location.href = link;

      if (action?.type === 'rateEventSpeaker' && action?.data?.activity)
        setEvaluatingSpeaker(action.data.activity);
    },
    [track],
  );

  const handleOptIn = useCallback(async () => {
    const { playerId } = oneSignalSetup;
    const win = window as any;
    const OneSignal = win.OneSignal || [];

    if (cookies.get(AUTH_TOKEN_COOKIE)) {
      if (user?._id) {
        await saveOptIn({
          playerId,
          userId: user._id,
        });
        await OneSignal.setExternalUserId(user._id);
      }
    } else {
      await saveOptIn({
        playerId,
      });
      await OneSignal.setExternalUserId('');
    }
  }, [oneSignalSetup, user._id]);

  useEffect(() => {
    const { hasInstantiedOneSignal, playerId } = oneSignalSetup;
    if (hasInstantiedOneSignal && playerId) handleOptIn();
  }, [handleOptIn, oneSignalSetup, user?._id]);

  const init = useCallback(async () => {
    const win = window as any;
    const OneSignal = win.OneSignal || [];
    await OneSignal.init(oneSignalConfig);
  }, [oneSignalConfig]);

  const setup = async (permission: string) => {
    if (permission === 'granted') {
      const win = window as any;
      const OneSignal = win.OneSignal || [];

      await OneSignal.registerForPushNotifications();
      const playerId = await OneSignal.getUserId();

      setOneSignalSetup({
        hasInstantiedOneSignal: true,
        playerId,
      });
    }
  };

  const instanceOneSignal = useCallback(() => {
    const win = window as any;
    const OneSignal = win.OneSignal || [];

    OneSignal.push(() => {
      OneSignal.SERVICE_WORKER_PARAM = { scope: '/sw.js' };
      OneSignal.SERVICE_WORKER_PATH = 'sw.js';
      OneSignal.SERVICE_WORKER_UPDATER_PATH = 'sw.js';
    });

    init();

    OneSignal.push(['getNotificationPermission', setup]);

    OneSignal.push([
      'addListenerForNotificationOpened',
      handleOpenedNotification,
    ]);
  }, [init, handleOpenedNotification]);

  return (
    <>
      <Script
        src="https://cdn.onesignal.com/sdks/OneSignalSDK.js"
        onLoad={instanceOneSignal}
      />
      {user?._id && (
        <RatingModal
          closeModalControlled={() => setEvaluatingSpeaker(null)}
          contentId={evaluatingSpeaker}
          showModalControlled={!!evaluatingSpeaker}
          type="eventActivity"
          userId={user._id}
          ShareComponent={<ShareArea />}
        />
      )}
    </>
  );
};

export default InitOnSignal;
