import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles, styled } from '@material-ui/core/styles';

export const Dialog = withStyles(() => ({
  paper: {
    width: '100%',
    maxWidth: 480,
    height: '100%',
    margin: '0 10px',
    borderRadius: '20px',
  },
}))(MuiDialog);

export const DialogContent = withStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px !important',
  },
}))(MuiDialogContent);

export const Iframe = styled('iframe')(() => ({
  border: 'none',
  width: '100%',
  borderWidth: '0px',
  overflow: 'scroll !important',
  '-webkit-overflow-scrolling': 'touch !important',
}));
