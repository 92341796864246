import { Dialog, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Modal = styled(Dialog)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& .MuiDialog-container': {
    width: '100%',
  },

  '& .MuiDialog-paper': {
    width: '100%',
    height: '100%',
    margin: 0,
    borderRadius: 0,
  },

  '& .MuiDialog-paperScrollPaper': {
    maxHeight: 'unset',
    display: 'block',
  },

  '& .swiper-container': {
    height: '100%',
  },
});

export const Header = styled('div')(({ theme }) => ({
  height: 60,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  zIndex: 1,
  alignItems: 'center',

  '& button': {
    padding: '0',
  },
}));

export const Container = styled('section')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  cursor: 'pointer',
  backgroundSize: 'cover!important',
  position: 'relative',
});

export const List = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center',

  '& svg': {
    fontSize: 12,
  },
}));

export const DotButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export const BackdropTop = styled('div')({
  background: 'linear-gradient(to top, transparent 0%, #0000009e 100%)',
  position: 'absolute',
  width: '100%',
  height: '50%',
  top: 0,
});

export const BackdropBottom = styled('div')({
  background: 'linear-gradient(to bottom, transparent 0%, #0000009e 100%)',
  position: 'absolute',
  width: '100%',
  height: '50%',
  bottom: 0,
});
