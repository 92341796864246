import { memo } from 'react';

import { isMobile } from 'react-device-detect';

import { ICardStoriesProps } from '@components/core/StoriesList/Card';
import Mobile from './Views/Mobile';
import Desktop from './Views/Desktop';
import { Modal } from './styles';

export interface StoriesProps {
  stories: ICardStoriesProps[];
  open: boolean;
  handleClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  initialSlide?: number;
}

const Stories: React.FC<StoriesProps> = ({
  stories,
  open,
  handleClose,
  initialSlide,
}) => {
  return (
    <Modal fullScreen={!isMobile} open={open} onClose={handleClose}>
      {isMobile ? (
        <Mobile
          open={open}
          stories={stories}
          handleClose={handleClose}
          initialSlide={initialSlide}
        />
      ) : (
        <Desktop
          open={open}
          stories={stories}
          handleClose={handleClose}
          initialSlide={initialSlide}
        />
      )}
    </Modal>
  );
};

export default memo(Stories);
