import { useAuth } from '@hooks/auth';

import CloseIcon from '@material-ui/icons/Close';

import Backdrop from '@components/core/Backdrop';

import * as Styled from './styles';

const contentList = [
  {
    id: 1,
    text: '+200 horas de aulas',
  },
  {
    id: 2,
    text: 'Análises do que é notícia',
  },
  {
    id: 3,
    text: 'Vídeos com insights e dicas',
  },
  {
    id: 4,
    text: 'Podcasts objetivos',
  },
  {
    id: 5,
    text: 'Eventos online',
  },
  {
    id: 6,
    text: 'E muito mais!',
  },
];

interface LoginModalProps {
  open: boolean;
  title: string;
  description: JSX.Element;
  onClose(): void;
}

const Login: React.FC<LoginModalProps> = ({
  open,
  title,
  description,
  onClose,
}) => {
  const { setShowModalLogin, showModalLogin } = useAuth();

  const handleModalLogin = (signUp: boolean) => {
    setShowModalLogin(true, signUp);
  };

  return (
    <Styled.Dialog
      open={open && !showModalLogin}
      aria-labelledby="login"
      onClose={onClose}
      BackdropComponent={Backdrop}
    >
      <Styled.CloseButton onClick={onClose}>
        <CloseIcon fontSize="default" />
      </Styled.CloseButton>
      <Styled.Container>
        <Styled.Header>
          <Styled.Title>{title}</Styled.Title>
        </Styled.Header>
        <Styled.Body>
          <Styled.Content>
            <h3>Tenha acesso gratuito a:</h3>
            {contentList.map(item => (
              <Styled.ListItem key={item.id}>
                <img
                  src="https://startse-landings.s3.us-east-2.amazonaws.com/app/svg/blue-check.svg"
                  alt="Ícone de Check"
                />
                <p>{item.text}</p>
              </Styled.ListItem>
            ))}
            <Styled.Border />
          </Styled.Content>
          <Styled.Login>
            {description}
            <Styled.SignupButton
              onClick={() => {
                handleModalLogin(true);
              }}
            >
              Faça parte
            </Styled.SignupButton>
            <Styled.LoginButton
              onClick={() => {
                handleModalLogin(false);
              }}
            >
              Caso já tenha conta, clique aqui
            </Styled.LoginButton>
          </Styled.Login>
        </Styled.Body>
      </Styled.Container>
    </Styled.Dialog>
  );
};

export default Login;
