import { IconButton, Button as MaterialButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  backgroundSize: 'cover!important',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: isMobile ? 'space-between' : 'center',
  position: 'relative',

  '& > section': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: 20,

    '& > div': {
      zIndex: 4,

      '& button': {
        padding: 0,
      },
    },
  },

  '& h3': {
    fontFamily: 'Barlow',
    lineHeight: '22px',
    letterSpacing: 0,
    fontSize: 13,
    color: theme.palette.common.white,
    fontWeight: 600,
    textTransform: 'uppercase',
    background: '#ffffff42',
    borderRadius: 10,
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(2),
    margin: 0,
    zIndex: 1,
  },

  '& h1': {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    margin: 0,
    fontFamily: 'Barlow',
    lineHeight: '32px',
    letterSpacing: 0,
    fontSize: 30,
    color: theme.palette.common.white,
    fontWeight: '400',
    zIndex: 1,
  },

  '& p': {
    marginBottom: theme.spacing(2),
    margin: 0,
    fontFamily: 'Barlow',
    lineHeight: '14px',
    letterSpacing: 0,
    fontSize: 12,
    color: theme.palette.common.white,
    fontWeight: 500,
    zIndex: 1,
  },

  '& svg path.a': {
    fill: theme.palette.common.white,
    opacity: 1,
  },
}));

export const Button = styled(MaterialButton)(({ theme }) => ({
  width: 130,
  height: 53,
  background: theme.palette.common.black,
  borderRadius: 27,
  margin: 0,
  fontFamily: 'Barlow',
  lineHeight: '14px',
  letterSpacing: 0,
  fontSize: 12,
  color: theme.palette.common.white,
  fontWeight: 500,
  zIndex: 3,
  position: 'relative',

  '&:hover': {
    background: theme.palette.common.black,
  },
}));

export const FavoriteButton = styled(IconButton)({
  height: 45,

  '& span div': {
    width: 45,
    height: 45,
  },
});

export const BackdropTop = styled('div')({
  background: 'linear-gradient(to top, transparent 0%, #0000009e 100%)',
  position: 'absolute',
  width: '100%',
  height: '60%',
  top: 0,
});

export const BackdropBottom = styled('div')({
  background: 'linear-gradient(to bottom, transparent 0%, #0000009e 100%)',
  position: 'absolute',
  width: '100%',
  height: '60%',
  bottom: '0',
});

export const PrevStory = styled('div')({
  position: 'absolute',
  width: '40%',
  height: '100%',
  left: 0,
  zIndex: 2,
  top: 0,

  '&::after': {
    display: 'none',
  },
});

export const NextStory = styled('div')({
  position: 'absolute',
  width: '60%',
  height: '100%',
  right: 0,
  zIndex: 2,
  top: 0,

  '&::after': {
    display: 'none',
  },
});

export const Image = styled('img')({
  zIndex: 1,
});
