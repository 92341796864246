import { useMemo } from 'react';
import { ContainerProgress, Label } from './styles';

import CircularProgress from './CircularProgress';
import { ILevel } from '..';

interface HeaderProps {
  totalIndications: number;
  levels: ILevel[];
}

const Header: React.FC<HeaderProps> = ({ totalIndications, levels }) => {
  const percetage = useMemo(() => {
    let nextLevel = levels.find(
      level => level.minIndications > totalIndications,
    )?.minIndications;
    if (!nextLevel) {
      nextLevel = levels[0].minIndications;
    }
    return Math.round((totalIndications * 100) / nextLevel);
  }, [levels, totalIndications]);

  return (
    <ContainerProgress>
      <CircularProgress
        totalIndications={totalIndications}
        percetage={percetage}
      />
      <Label>
        Quando você atingir pelo menos 5 indicações, você concorrerá
        automaticamente a uma viagem para o <span>Vale do Silício.</span>
      </Label>
    </ContainerProgress>
  );
};

export default Header;
