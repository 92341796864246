import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '20px auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Loader: React.FC = () => {
  const { root } = useStyles();
  return (
    <div className={root}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
