import { useSnackbar } from '@hooks/snackbar';
import {
  WhatsApp,
  Twitter,
  MailOutline,
  FileCopyOutlined,
} from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import { useCallback, useMemo } from 'react';

import Facebook from '../../../../assets/svg/facebook.svg';
import Messenger from '../../../../assets/svg/messenger.svg';

import {
  Container,
  Button,
  Description,
  ContainerLink,
  CopyButton,
  ContainerButtons,
  ContainerShare,
} from './styles';

interface ShareProps {
  linkToShare: string;
}

const Share: React.FC<ShareProps> = ({ linkToShare }) => {
  const { showSnackbar } = useSnackbar();

  const text = useCallback(
    (source: string) =>
      'Acabei de me inscrever na Newsletter da StartSe. Receba nosso resumo diário com o que realmente importa. Inovação e negócios em 5 minutos ou menos para que você não fique para trás.' +
      '%0D%0A %0D%0A' +
      `Inscreva-se pelo link ${linkToShare}&refSource=${source}`,
    [linkToShare],
  );

  const twitterText =
    'Acabei de me inscrever na Newsletter da StartSe. Receba nosso resumo diário com o que realmente importa. Inovação e negócios em 5 minutos ou menos para que você não fique para trás.';

  const shares = useMemo(
    () => [
      {
        link: `https://www.facebook.com/sharer/sharer.php?u=${linkToShare}&refSource=facebook`,
        icon: <Facebook />,
      },
      {
        link: `https://web.whatsapp.com/send?text=${text('whatsapp')}`,
        icon: <WhatsApp />,
      },
      {
        link: `https://twitter.com/intent/tweet?url=${linkToShare}&refSource=twitter&text=${twitterText}&via=@startseoficial`,
        icon: <Twitter />,
      },
      {
        link: `http://www.facebook.com/dialog/send?app_id=537066536500742&link=${linkToShare}&refSource=facebook&redirect_uri=https://www.app.startse.com/`,
        icon: <Messenger />,
      },
      {
        link: `mailto:?subject=Newsletter StartSe Agora | Receba nosso resumo diário com o que realmente importa | Inovação e negócios em 5 minutos ou menos para que você não fique para trás.
          &body=${text('mail')}`,
        icon: <MailOutline />,
      },
    ],
    [linkToShare, text],
  );

  const copyButtonClick = () => {
    copy(`${linkToShare}&refSoucer=copy`);
    showSnackbar({ message: 'Link copiado!', type: 'success' });
  };

  const shareButtonClick = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <Container>
      <Description>
        Compartilhe seu link em suas redes sociais para convidar muitos amigos.
        <br />
        <span>(WhatsApp, Facebook, Twitter, email)</span>
      </Description>
      <ContainerShare>
        <ContainerLink onClick={copyButtonClick}>
          <span>{linkToShare}</span>
          <CopyButton variant="contained" startIcon={<FileCopyOutlined />} />
        </ContainerLink>
        <ContainerButtons>
          {shares.map(share => (
            <Button
              key={share.link}
              variant="contained"
              startIcon={share.icon}
              onClick={() => shareButtonClick(share.link)}
            />
          ))}
        </ContainerButtons>
      </ContainerShare>
    </Container>
  );
};

export default Share;
