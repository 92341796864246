import { createContext, useContext, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ILoadingContextData {
  addLoading(): void;
  removeLoading(): void;
}

const LoadingContext = createContext<ILoadingContextData>(
  {} as ILoadingContextData,
);

const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(0);

  const addLoading = (): void => setLoading(old => old + 1);

  const removeLoading = (): void => setLoading(old => old - 1);

  return (
    <LoadingContext.Provider value={{ addLoading, removeLoading }}>
      <Backdrop open={loading > 0} style={{ zIndex: 1400 }}>
        <CircularProgress />
      </Backdrop>
      {children}
    </LoadingContext.Provider>
  );
};

function useLoading(): ILoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('Loading must be used within a LoadingProvider');
  }

  return context;
}

export { useLoading, LoadingProvider };
