const ArticlesIcon: React.FC = () => {
  return (
    <svg width="30.076" height="30.076" viewBox="0 0 30.076 30.076">
      <g transform="translate(3.759 2.506)">
        <path
          className="a"
          fill="#acb1c0"
          d="M6.439,25.063a8.7,8.7,0,0,1-1.467-.118L4.7,24.893a5.573,5.573,0,0,1-3.464-2.111A6.951,6.951,0,0,1,0,18.535V6.528a9.312,9.312,0,0,1,.076-1.2C.514,1.941,2.834,0,6.439,0h9.68c3.994,0,6.4,2.4,6.438,6.431v12.1c0,4.087-2.407,6.528-6.438,6.528ZM1.8,6.528V18.535c0,3.12,1.56,4.7,4.638,4.7h9.669c3.07,0,4.626-1.582,4.626-4.7V6.528c0-3.12-1.556-4.7-4.626-4.7H6.439C3.361,1.826,1.8,3.408,1.8,6.528ZM6.745,18.745a.907.907,0,0,1,0-1.815h9.036a.907.907,0,0,1,0,1.815Zm9.036-5.3H6.745a.921.921,0,0,1,0-1.827h9.036a.9.9,0,0,1,.866.425.934.934,0,0,1,0,.977.9.9,0,0,1-.866.425Zm-9.025-5.3a.92.92,0,0,1,0-1.826h3.438a.92.92,0,0,1,0,1.826Z"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
};

export default ArticlesIcon;
