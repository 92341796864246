import { styled } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Content = styled(ScrollContainer)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, auto))',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  scrollBehavior: 'smooth',
  gap: '80px 70px',
  margin: 40,

  [theme.breakpoints.down('sm')]: {
    margin: 20,
    gap: '38px 20px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(35vw, auto))',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 0',
  marginLeft: 40,
  borderRadius: 13,
  backgroundColor: '#ffffff',
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.11)',
  width: 'calc(100% - 80px)',
  maxWidth: 1449,

  [theme.breakpoints.down('sm')]: {
    width: 'initial',
    marginLeft: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
  },
}));

export const ContainerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 16,
  paddingBottom: 32,
  width: '95%',
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    marginLeft: 20,
    paddingTop: 0,
    paddingBottom: 18,
  },
}));

export const Box = styled('div')(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    marginLeft: 20,
  },
}));
