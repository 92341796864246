import api from '../api';

export const getFeatured = async (): Promise<any> => {
  const videosReponse = await api.get('videos/featured');
  return videosReponse.data;
};

export const findMacrotags = async (limit = 8, offset = 0): Promise<any> => {
  const videosReponse = await api.get(
    `videos/macrotags?limit=${limit}&offset=${offset}`,
  );
  return videosReponse.data;
};

export const findAll = async (
  limit = 10,
  offset = 0,
  macrotag = '',
): Promise<any> => {
  const videosReponse = await api.get(
    `videos?limit=${limit}&offset=${offset}${
      !macrotag || macrotag === 'n' ? '' : `&macrotag=${macrotag}`
    }`,
  );
  return videosReponse.data;
};

export const getMacrotag = async (slug: string): Promise<any> => {
  if (!slug) return null;

  const videosReponse = await api.get(`videos/macrotags/${slug}`);
  return videosReponse.data;
};

export const get = async (slug: string): Promise<any> => {
  if (!slug) return null;

  const videosReponse = await api.get(`videos/${slug}`);
  return videosReponse.data;
};
