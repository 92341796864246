import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Container = styled('footer')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  padding: theme.spacing(3),
  backgroundColor: '#F5F5F5',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& svg': {
    '& path.a': {
      fill: theme.palette.primary.main,
    },
    '& circle': {
      fill: theme.palette.primary.main,
    },
    '& g circle.a': {
      fill: theme.palette.primary.main,
    },
    '@media screen and (max-width: 780px)': {
      '& g circle.a': {
        fill: theme.palette.primary.main,
      },
    },
  },
  '& p': {
    display: 'block',
    flexDirection: 'column',
    flexBasis: '100%',
    fontSize: 13,
    fontFamily: 'Barlow',
    lineHeight: '16px',
    color: '#333333',
    listStyleType: 'none',
    margin: '6px',
  },
  '@media screen and (max-width: 780px)': {
    flexDirection: 'column',
    backgroundColor: '#f7f7f7',
  },
}));

export const ContainerLogo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gridGap: '20px',
  alignItems: 'center',
  '@media screen and (max-width: 780px)': {
    textAlign: 'center',
    flexDirection: 'column',
    marginBottom: '20px',
  },
}));

export const FooterIcons = styled('div')(() => ({
  '& svg': { color: 'black', marginLeft: '5px' },
  '& a': { width: '40px' },
}));

export const Info = styled('div')(() => ({
  display: 'block',
  flexDirection: 'column',
  flexBasis: '100%',
  color: '#333333',
  fontSize: '13px',
  fontFamily: 'Barlow',
  lineHeight: '16px',
  margin: '6px',
}));

export const Contatos = styled('div')(() => ({
  display: 'block',
  flexDirection: 'column',
  flexBasis: '100%',
  color: '#333333',
  fontSize: '13px',
  fontFamily: 'Barlow',
  lineHeight: '16px',
  margin: '6px',
}));

export const Column = styled('div')(() => ({
  display: 'block',
  flexDirection: 'column',
  flexBasis: '100%',
}));

export const Mt = styled('div')(() => ({
  marginTop: '10px',
}));

export const Bold = styled('span')(() => ({
  fontWeight: 700,
}));

export const Fs12 = styled('span')(() => ({
  fontSize: '12px',
}));

export const Fs11 = styled('div')(() => ({
  fontSize: '11px',
  align: 'center',
  textAlign: 'center',
  padding: '2px',
}));
