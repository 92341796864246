import { WebserieEntity } from '@models/webserie.interface';
import api from '../api';

export const getFeatured = async (): Promise<any> => {
  const webseriesResponse = await api.get('webseries');
  return webseriesResponse.data;
};

export const findAll = async (limit = 5, offset = 0): Promise<any> => {
  const params = new URLSearchParams();
  params.append('limit', String(limit));
  params.append('offset', String(offset));
  const webseriesResponse = await api.get('webseries', { params });
  return webseriesResponse.data.webseries;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const findBySlug = async (slug: any): Promise<WebserieEntity> => {
  const webserieResponse = await api.get(`/webseries/${slug}`);
  return webserieResponse.data;
};

export const findFeatured = async (): Promise<any> => {
  const webserieFeatured = await api.get('/webseries/featured');

  return webserieFeatured.data;
};

export const findReleases = async (): Promise<any> => {
  const releaseEpisodes = await api.get('/webseries/episodes/releases');

  return releaseEpisodes.data;
};
