import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export const Dialog = withStyles(() => ({
  root: {
    padding: '0px',
    borderRadius: '15px',
  },
  paper: {
    width: '100%',
    height: '100%',
    borderRadius: '15px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  paperWidthSm: {
    width: '100% !important',
    maxWidth: '100%',
  },
}))(MuiDialog);

export const DialogContent = withStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    borderRadius: '15px',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flexDirection: 'column',
    padding: '0px !important',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(44px)',
    boxShadow: '0px 0px 60px #00000036',
    '@media screen and (max-width: 780px)': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
}))(MuiDialogContent);

export const CloseButton = withStyles(() => ({
  root: {
    zIndex: 99,
    position: 'absolute',
    top: '2px',
    right: '24px',
    cursor: 'pointer',
    borderRadius: '50%',
    '@media screen and (max-width: 780px)': {
      top: '9px',
      right: '11px',
    },
  },
}))(IconButton);

export const Iframe = styled('iframe')(() => ({
  position: 'absolute',
  borderRadius: '15px',
  width: '100%',
  height: '100%',
  borderWidth: '0px',
  overflow: 'scroll !important',
}));

export const LoginHomeContainer = styled(Box)(({ theme }) => ({
  width: '500px !important',
  [theme.breakpoints.down('xs')]: {
    width: '320px !important',
    maxWidth: '320px !important',
  },
}));
