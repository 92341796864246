/* eslint-disable no-shadow */
export enum ContentType {
  article = 'article',
  video = 'video',
  podcast = 'podcast',
  webserie = 'webserie',
  webserieEpisode = 'webserieEpisode',
  program = 'program',
  speaker = 'speaker',
  podcastEpisode = 'podcastEpisode',
  podcastPlaylist = 'podcastPlaylist',
}
