import {
  Container,
  ContainerLabels,
  Description,
  SubTitle,
  Title,
} from './styles';

interface ChallengeItemProps {
  icon: JSX.Element;
  title: string;
  subTitle: string;
  description: string;
}

const ChallengeItem: React.FC<ChallengeItemProps> = ({
  description,
  icon,
  subTitle,
  title,
}) => {
  return (
    <Container>
      {icon}
      <ContainerLabels>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <Description>{description}</Description>
      </ContainerLabels>
    </Container>
  );
};

export default ChallengeItem;
