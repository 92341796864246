export interface IPodcastCache {
  currentTime: number;
}

export const getEpisodeCache = (episodeId: string): IPodcastCache => {
  const episodesCache = JSON.parse(
    localStorage.getItem('podcastEpisodesCache'),
  );

  if (episodesCache && episodesCache[episodeId]) {
    return episodesCache[episodeId];
  }
  return { currentTime: 0 };
};

export const setEpisodeCache = (
  episodeId: string,
  data: IPodcastCache,
): void => {
  const episodesCache = JSON.parse(
    localStorage.getItem('podcastEpisodesCache'),
  );
  if (episodeId && episodesCache) {
    episodesCache[episodeId] = data;
  } else {
    localStorage.setItem(
      'podcastEpisodesCache',
      JSON.stringify({ [episodeId]: data }),
    );
    return;
  }
  localStorage.setItem('podcastEpisodesCache', JSON.stringify(episodesCache));
};
