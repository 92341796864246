import { styled } from '@material-ui/core';

export const Container = styled('div')(() => ({
  position: 'relative',
  paddingBottom: '56.25%',
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '100%',
    minHeight: '100%',
  },
  '--plyr-color-main': '#1580ED',
}));
