import MuiDialog from '@material-ui/core/Dialog';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, styled } from '@material-ui/core/styles';

export const Dialog = withStyles(() => ({
  paper: {
    maxWidth: '365px',
    borderRadius: '20px',
    position: 'relative',
    padding: '20px 20px 40px',
    margin: 'auto 10px',
  },
}))(MuiDialog);

export const CloseButton = withStyles(() => ({
  root: {
    position: 'absolute',
    top: 10,
    right: 10,
    borderRadius: '50%',
  },
}))(IconButton);

export const Title = styled('h2')(() => ({
  margin: '10px auto',
  textAlign: 'center',
  color: '#333333',
  fontFamily: 'Barlow',
  fontSize: '24px',
  padding: '0px 20px',
}));

export const Text = styled('p')(() => ({
  margin: '10px auto 20px',
  textAlign: 'center',
  color: '#2D2D2D',
  fontFamily: 'IBM Plex Sans',
  fontSize: '16px',
}));

export const Area = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  '& > *': {
    margin: '0px 5px',
  },
}));

export const LinkButton = withStyles(() => ({
  root: {
    border: '2px #707070 solid',
    width: '35px',
    height: '35px',
    borderRadius: '50%',
  },
}))(ButtonBase);
