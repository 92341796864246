import { styled } from '@material-ui/core';
import { calcSizeBasedInVhScale } from '@utils/metrics';

export const Container = styled('div')({
  '& p': {
    margin: 0,
    padding: 0,
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: calcSizeBasedInVhScale(10),
});

export const Description = styled('p')({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(20),
  '& span': {
    fontWeight: 'bold',
  },
});

export const LabelNivel = styled('p')({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(18),
});

export const ContainerProgress = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Title = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(28),
  fontWeight: 600,
  textAlign: 'center',
  margin: `${calcSizeBasedInVhScale(5)} 0 !important`,

  '& span': {
    fontWeight: 'bold',
    color: '#007EF5',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    margin: '5px 0 !important',
  },
}));

export const ContainerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
