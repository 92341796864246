import { styled } from '@material-ui/core';

interface DashedDividerProps {
  orientation?: 'vertical' | 'horizontal';
  height?: string;
}

export default styled('div')(
  ({ orientation = 'horizontal', height }: DashedDividerProps) => ({
    minWidth: orientation === 'horizontal' ? '100%' : '2px',
    minHeight: orientation === 'horizontal' ? '2px' : height || '100%',

    backgroundImage:
      orientation === 'horizontal'
        ? `linear-gradient(to right, #0000004D 12px, transparent 4px)`
        : `linear-gradient(to bottom, #0000004D 12px, transparent 4px)`,

    backgroundPosition: 'left',
    backgroundSize: orientation === 'horizontal' ? '20px 2px' : '2px 21px',
    backgroundRepeat: orientation === 'horizontal' ? 'repeat-x' : 'repeat-y',
  }),
);
