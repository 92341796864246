import { styled } from '@material-ui/core';
import { calcSizeBasedInVhScale } from '@utils/metrics';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: `${calcSizeBasedInVhScale(10)} 0`,
  height: calcSizeBasedInVhScale(150),

  '& svg': {
    fill: '#424242',
    width: calcSizeBasedInVhScale(45),
    height: calcSizeBasedInVhScale(50),
    minWidth: calcSizeBasedInVhScale(45),
    minHeight: calcSizeBasedInVhScale(50),
  },

  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    '& svg': {
      width: '35px',
      height: '40px',
      minWidth: '35px',
      minHeight: '40px',
    },
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(20),
  fontWeight: 'bold',

  [theme.breakpoints.down('sm')]: {
    fontSize: '15px',
  },
}));

export const SubTitle = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(16),
  fontWeight: 'bold',

  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
  },
}));

export const Description = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(16),

  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
  },
}));

export const ContainerLabels = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: calcSizeBasedInVhScale(20),

  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));
