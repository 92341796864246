import {
  Button as MaterialButton,
  IconButton as MaterialIconButton,
  styled,
  makeStyles,
} from '@material-ui/core';
import { MobileButtonProps } from './Button/Mobile';

export const IconButton = styled(MaterialIconButton)({
  position: 'relative',
  zIndex: 4,
});

export const ContainerDesktop = styled(MaterialButton)({
  color: 'white!important',
  borderRadius: 24,
  height: 35,
  width: 120,

  '& span svg': {
    marginRight: '8px',
  },
});

export const ContainerMobile = styled(IconButton)({
  borderRadius: '50%',
  width: 35,
  height: 35,

  '& span': {
    width: 35,
  },
});

export const useStyles = makeStyles({
  dark: {
    '&.MuiIconButton-root.Mui-disabled': {
      background: 'transparent',
    },
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
    '& span svg': {
      '& path': {
        stroke: '#818181',
        fill: (props: Pick<MobileButtonProps, 'toggle'>) =>
          props.toggle ? '#818181' : 'transparent',
      },
    },
  },
  superdark: {
    '& span': {
      color: '#000000',
    },
    '&.MuiIconButton-root.Mui-disabled': {
      background: 'transparent',
    },
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
    '& span svg': {
      '& path': {
        stroke: '#000000',
        fill: (props: Pick<MobileButtonProps, 'toggle'>) =>
          props.toggle ? '#000000' : 'transparent',
      },
    },
  },
  white: {
    background: 'transparent',
    '&.MuiIconButton-root.Mui-disabled': {
      background: 'transparent',
    },
    '&:hover': {
      background: 'transparent',
    },
    '& span svg': {
      fill: 'grey',
      '& path': {
        fill: (props: Pick<MobileButtonProps, 'toggle'>) =>
          props.toggle ? 'white' : 'transparent',
      },
    },
  },
});
