import {
  createContext,
  useEffect,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import cookies from '@utils/cookies';

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

type PromptContextData = {
  hidePrompt?: () => void;
  notShowPrompt: boolean;
  deferredEvt: IBeforeInstallPromptEvent | null;
};

const PromptContext = createContext<PromptContextData>({
  notShowPrompt: false,
  deferredEvt: null,
});

const PromptProvider: React.FC = ({ children }) => {
  const [
    deferredEvt,
    setDeferredEvt,
  ] = useState<IBeforeInstallPromptEvent | null>(null);

  const [isInstalled, setIsInstalled] = useState(false);

  const notShowPrompt = useMemo(() => {
    const cookieValidate = cookies.get('hideAddToHomeScreenPrompt');

    return !deferredEvt || isInstalled || cookieValidate === 'true';
  }, [deferredEvt, isInstalled]);

  const hidePrompt = useCallback(() => {
    setDeferredEvt(null);
    const today = new Date();
    today.setDate(today.getDate() + 7);
    cookies.set('hideAddToHomeScreenPrompt', 'true', {
      expires: new Date(today),
    });
  }, []);

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredEvt(e);
    };

    window.addEventListener('beforeinstallprompt', ready as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any);
    };
  }, []);

  useEffect(() => {
    const onInstall = () => {
      setIsInstalled(true);
    };

    window.addEventListener('appinstalled', onInstall as any);

    return () => {
      window.removeEventListener('appinstalled', onInstall as any);
    };
  }, []);

  return (
    <PromptContext.Provider value={{ hidePrompt, notShowPrompt, deferredEvt }}>
      {children}
    </PromptContext.Provider>
  );
};

function usePrompt(): PromptContextData {
  const ctx = useContext(PromptContext);
  if (!ctx) {
    throw new Error('Prompt must be used within a PromptProvider');
  }
  return ctx;
}

export { usePrompt, PromptProvider };
