import { memo, useState } from 'react';

import SwiperCore, { Navigation, Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Footer } from './styles';
import Content from '../../Content';
import { StoriesProps } from '../..';

SwiperCore.use([Navigation, Pagination, Virtual]);

const Mobile: React.FC<StoriesProps> = ({
  stories,
  handleClose,
  initialSlide,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore | undefined>();

  return (
    <>
      <Swiper
        virtual
        centeredSlides
        slidesPerView={1}
        preventClicksPropagation
        preventClicks
        pagination={{ el: '.swiper-pagination', clickable: true }}
        onInit={e => setSwiper(e)}
        initialSlide={initialSlide}
      >
        {stories.map((story, index) => (
          <SwiperSlide virtualIndex={index} key={story?.title}>
            <Content story={story} handleClose={handleClose} swiper={swiper} />
          </SwiperSlide>
        ))}

        <Footer className="swiper-pagination" />
      </Swiper>
    </>
  );
};

export default memo(Mobile);
