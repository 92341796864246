import Dialog from '@material-ui/core/Dialog';
import * as Style from './styles';

export interface IAlertConfirm {
  question: string;
  open: boolean;
  callback: () => void;
  onClose: () => void;
}

const AlertConfirm: React.FC<IAlertConfirm> = ({
  question,
  open,
  callback,
  onClose,
}) => {
  const handleConfirm = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (event) event.stopPropagation();
    callback();
    onClose();
  };

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (event) event.stopPropagation();

    onClose();
  };

  return (
    <Dialog
      PaperComponent={props => <Style.AlertBody {...props} />}
      BackdropProps={Style.BackdropProps}
      open={open}
      onClose={handleClose}
    >
      <Style.Content>
        <Style.Text>{question}</Style.Text>
      </Style.Content>
      <Style.Actions disableSpacing>
        <Style.Button onClick={handleClose} color="primary">
          Cancelar
        </Style.Button>
        <Style.Button onClick={handleConfirm} color="primary">
          Continuar
        </Style.Button>
      </Style.Actions>
    </Dialog>
  );
};

export default AlertConfirm;
