import { memo } from 'react';

import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { StoriesProps } from '..';
import LogoIcon from '../../../../assets/svg/logo-stories.svg';
import { Container } from './styles';

const Header: React.FC<Pick<StoriesProps, 'handleClose'>> = ({
  handleClose,
}) => {
  return (
    <Container>
      <section>
        <LogoIcon />
        <IconButton onClick={handleClose}>
          <CloseIcon htmlColor="white" />
        </IconButton>
      </section>
    </Container>
  );
};

export default memo(Header);
