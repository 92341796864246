import { useMemo } from 'react';
import Lottie from 'react-lottie';

import loadingAnimation from '@assets/animations/loadingAnimation.json';

import { ContainerFullLoading } from './styles';

interface LottieLoadingProps {
  loading: boolean;
  fullLoading?: boolean;
  size?: number;
}

const LottieLoading: React.FC<LottieLoadingProps> = ({
  loading,
  fullLoading = true,
  size = 300,
}) => {
  const defaultOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: loadingAnimation,
    }),
    [],
  );
  const renderLottie = useMemo(
    () => (
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        height={size}
        width={size}
      />
    ),
    [defaultOptions, size],
  );

  if (!loading) return null;

  if (!fullLoading) return renderLottie;
  return <ContainerFullLoading>{renderLottie}</ContainerFullLoading>;
};

export default LottieLoading;
