import { styled } from '@material-ui/core';

export const Container = styled('header')({
  width: '100%',

  '& section': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
  },

  '& button': {
    padding: '0',
    position: 'relative',
    zIndex: 3,
  },

  '& svg': {
    padding: '0',
    zIndex: 1,
  },
});
export const PrevStory = styled('div')({
  position: 'absolute',
  width: '40%',
  height: '100%',
  left: 0,
  zIndex: 2,
  top: 0,

  '&::after': {
    display: 'none',
  },
});

export const NextStory = styled('div')({
  position: 'absolute',
  width: '60%',
  height: '100%',
  right: 0,
  zIndex: 1,
  top: 64,

  '&::after': {
    display: 'none',
  },
});
