import {
  Typography as MaterialTypography,
  TypographyProps,
} from '@material-ui/core';

const Typography: React.FC<TypographyProps> = ({ children, ...rest }) => {
  return <MaterialTypography {...rest}>{children}</MaterialTypography>;
};

export default Typography;
