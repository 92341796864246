import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

const useStyles = makeStyles({
  root: {
    border: '1px solid #E0E0E0',
    color: '#d4d4d4',
    padding: '4px',
  },
});

export interface ISaveButton {
  onClick?: (event: React.MouseEvent) => void;
}

const SaveButton: React.FC<ISaveButton> = ({ onClick }) => {
  const { root } = useStyles();
  return (
    <IconButton className={root} onClick={e => (onClick ? onClick(e) : null)}>
      <BookmarkBorderIcon />
    </IconButton>
  );
};

export default SaveButton;
