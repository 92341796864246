/* eslint-disable no-shadow */
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  Dispatch,
  useCallback,
} from 'react';

import IFavorite from '@models/favorite.interface';
import { FavoriteService } from '@services/content';
import { ActionMap } from '@utils/format';
import { useUser } from '@hooks/user';

export enum FavoriteTypes {
  Create = 'CREATE_FAVORITES',
  Delete = 'DELETE_FAVORITES',
  List = 'LIST_FAVORITES',
}

interface IFavoriteContext {
  state: IFavoriteContextData;
  dispatch: Dispatch<FavoriteActions>;
}

export interface IFavoriteContextData {
  total: number;
  offset: number;
  limit: number;
  favorites: IFavorite[];
}

type FavoritePayload = {
  [FavoriteTypes.Create]: IFavorite;
  [FavoriteTypes.List]: IFavoriteContextData;
  [FavoriteTypes.Delete]: {
    favoriteId: string;
  };
};

export type FavoriteActions =
  ActionMap<FavoritePayload>[keyof ActionMap<FavoritePayload>];

export const favoriteReducer = (
  state: IFavoriteContextData,
  action: FavoriteActions,
): IFavoriteContextData => {
  switch (action.type) {
    case FavoriteTypes.Create:
      return {
        ...state,
        total: state.total + 1,
        favorites: [action.payload, ...state.favorites],
      };
    case FavoriteTypes.List:
      return {
        ...action.payload,
      };
    case FavoriteTypes.Delete:
      return {
        ...state,
        total: state.total - 1,
        favorites: state.favorites.filter(
          favorite => favorite.id !== action.payload.favoriteId,
        ),
      };
    default:
      return state;
  }
};

const initialState = {
  total: 0,
  offset: 0,
  limit: 0,
  favorites: [],
};

const FavoriteContext = createContext<{
  state: IFavoriteContextData;
  dispatch: Dispatch<FavoriteActions>;
}>({ state: initialState, dispatch: () => null });

const FavoriteProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(favoriteReducer, initialState);
  const { user } = useUser();
  const limit = 9999;
  const offset = 0;

  const initialFetch = useCallback(async () => {
    const data = await FavoriteService.findAll(user._id, limit, offset);
    dispatch({
      type: FavoriteTypes.List,
      payload: data,
    });
  }, [user]);

  useEffect(() => {
    if (user && user?._id) {
      initialFetch();
    }
  }, [user, user?._id, initialFetch]);

  return (
    <FavoriteContext.Provider value={{ state, dispatch }}>
      {children}
    </FavoriteContext.Provider>
  );
};

function useFavorites(): IFavoriteContext {
  const context = useContext(FavoriteContext);

  if (!context) {
    throw new Error('Favorite must be used within a FavoriteProvider');
  }

  return context;
}

export { useFavorites, FavoriteProvider };
