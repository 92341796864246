import api from '../api';

export const getFeatured = async (): Promise<any> => {
  const articlesResponse = await api.get('articles/featured');
  return articlesResponse.data;
};

export const findAll = async (
  limit = 10,
  offset = 0,
  tags = null,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('limit', String(limit));
  params.append('offset', String(offset));
  if (tags) params.append('tags', String(tags));
  const articlesResponse = await api.get('articles', { params });
  return articlesResponse.data;
};
