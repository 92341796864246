import { styled, Box } from '@material-ui/core';
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButton as MuiToggleButton,
} from '@material-ui/lab';

export const ContainerMenu = styled(Box)({});

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)({
  display: 'flex',
  flexWrap: 'wrap',
});

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  fontFamily: 'Barlow',
  height: '20px',
  border: '0px',
  borderRadius: '0px',
  margin: '12px 0',
  marginRight: '25px',
  fontWeight: 600,
  fontSize: '24px',
  textTransform: 'capitalize',
  '& p': {
    margin: '0px',
  },
  '&.MuiToggleButton-root.Mui-selected': {
    color: '#0080ED',
    borderBottom: '2px solid #0080ED',
    backgroundColor: 'transparent',
  },
  '&.MuiToggleButton-root': {
    padding: 0,
    paddingBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    margin: '6px 0',
    marginRight: '10px',
  },
}));
