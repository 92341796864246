import { useTracking } from '@hooks/tracking';
import { ContainerMenu, ToggleButton, ToggleButtonGroup } from './styles';

export interface ITabsValues {
  label: string;
  value: string | number;
}
interface BasicTabsProps {
  values: ITabsValues[];
  selectedValue: string | number;
  setSelectedValue(e: any): void;
}

const BasicTabs: React.FC<BasicTabsProps> = ({
  values,
  selectedValue,
  setSelectedValue,
}) => {
  const { track } = useTracking();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedValue(newValue);
  };
  return (
    <ContainerMenu>
      <ToggleButtonGroup
        value={selectedValue}
        exclusive
        onChange={handleChange}
        aria-label="text alignment"
      >
        {values.map(e => (
          <ToggleButton
            value={e.value}
            onClick={() => track('Ideal format menu', { tabName: e.value })}
          >
            <p>{e.label}</p>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ContainerMenu>
  );
};

export default BasicTabs;
