import { createContext, useContext, useState } from 'react';
import LoginModal from '@components/core/LoginModal';

interface IAdLoginContextData {
  setAdLogin(title: string, description: JSX.Element): void;
  setShowAdLogin(value: boolean): void;
  showAdLogin: boolean;
}

const AdLoginContext = createContext<IAdLoginContextData>(
  {} as IAdLoginContextData,
);

const AdLoginProvider: React.FC = ({ children }) => {
  const [showAdLogin, setShowAdLogin] = useState(false);
  const [adLoginTitle, setAdLoginTitle] = useState('');
  const [adLoginDescription, setAdLoginDescription] = useState(
    null as JSX.Element,
  );

  const setAdLogin = (title: string, description: JSX.Element): void => {
    setAdLoginTitle(title);
    setAdLoginDescription(description);
  };

  return (
    <AdLoginContext.Provider
      value={{ setShowAdLogin, setAdLogin, showAdLogin }}
    >
      <LoginModal
        open={showAdLogin}
        title={adLoginTitle}
        description={adLoginDescription}
        onClose={() => setShowAdLogin(false)}
      />
      {children}
    </AdLoginContext.Provider>
  );
};

function useAdLogin(): IAdLoginContextData {
  const context = useContext(AdLoginContext);

  if (!context) {
    throw new Error('AdLogin must be used within a AdLoginProvider');
  }

  return context;
}

export { useAdLogin, AdLoginProvider };
