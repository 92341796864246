import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { isDesktop } from 'react-device-detect';

import { useAuth } from '@hooks/auth';
import { useUser } from '@hooks/user';
import { useTracking } from '@hooks/tracking';

import MuiBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@components/core/Backdrop';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { Dialog, Iframe, DialogContent, CloseButton } from './styles';

const Login: React.FC = () => {
  const {
    showModalLogin,
    setShowModalLogin,
    loginURL,
    signUp,
    userIsNotAllowedToRead,
    setUserIsNotAllowedToRead,
    displaySmall,
  } = useAuth();
  const router = useRouter();
  const { user } = useUser();
  const { track } = useTracking();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingIframe, setIsLoadingIframe] = useState(false);

  useEffect(() => {
    const handleMessages = message => {
      if (
        !(loginURL || '').includes(message.origin) ||
        (typeof message.data !== 'string' && !(message.data instanceof String))
      )
        return;

      const data = JSON.parse(message.data);

      if (data.action !== 'login') return;

      if (
        data &&
        data.redirect &&
        data.redirect.includes(encodeURIComponent('artigos/'))
      )
        localStorage.setItem('userJustLogIn', 'true');

      window.location.href = data.redirect;
      setShowModalLogin(false, signUp, displaySmall);
      setUserIsNotAllowedToRead(false);
      setIsLoading(true);
    };

    window.addEventListener('message', handleMessages);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, [
    displaySmall,
    loginURL,
    setShowModalLogin,
    setUserIsNotAllowedToRead,
    showModalLogin,
    signUp,
  ]);

  useEffect(() => {
    if (!showModalLogin) return;

    setIsLoadingIframe(true);
  }, [showModalLogin]);

  const handleLocation = () => {
    const location = window.location.pathname.split('/');

    switch (location[1]) {
      case 'artigos':
        return 'Article';
      case 'eventos':
        return 'Events';
      case 'aulas':
        return 'Video';
      case '':
        return 'Home';
      default:
        return null;
    }
  };

  const onClose = () => {
    if (handleLocation())
      track('closed_modal_video_or_article', {
        closedPoint: handleLocation(),
      });

    setShowModalLogin(false, signUp, displaySmall);
    setUserIsNotAllowedToRead(false);

    window.postMessage(
      {
        name: 'closed_modal_video_or_article',
      },
      `${process.env.NEXT_PUBLIC_APP}`,
    );
  };

  if (user && user._id) return null;

  if (isLoading)
    return (
      <MuiBackdrop open style={{ zIndex: 90 }}>
        <CircularProgress />
      </MuiBackdrop>
    );

  const style = {
    top: router.pathname === '/podcasts' ? 20 : 8,
    right: router.pathname === '/podcasts' ? 24 : 12,
    borderRadius: '50%',
    zIndex: 99,
  };

  return (
    <Dialog
      open={showModalLogin}
      aria-labelledby="login"
      onClose={onClose}
      BackdropComponent={Backdrop}
      style={displaySmall ? { maxWidth: '600px', margin: 'auto' } : {}}
    >
      <DialogContent dividers style={{ WebkitOverflowScrolling: 'touch' }}>
        {isLoadingIframe && (
          <CircularProgress style={{ position: 'absolute' }} />
        )}
        <Iframe
          src={`${loginURL}&signUp=${
            signUp ? 'true' : 'false'
          }&userIsNotAllowedToRead=${
            userIsNotAllowedToRead ? 'true' : 'false'
          }`}
          title="Startse login modal"
          frameBorder="0"
          scrolling="yes"
          onLoad={() => setIsLoadingIframe(false)}
          style={{ opacity: isLoadingIframe ? '0' : '1' }}
        />
        {isDesktop ? (
          <CloseButton onClick={onClose} style={style}>
            <CloseIcon fontSize="default" />
          </CloseButton>
        ) : (
          <CloseButton onClick={onClose}>
            <CloseIcon fontSize="default" />
          </CloseButton>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Login;
