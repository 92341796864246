import Image from 'next/image';

import { Box, styled, Theme } from '@material-ui/core';

import { calcSizeBasedInVhScale } from '@utils/metrics';

interface HorizontalText {
  webserieStyle: boolean;
  theme: Theme;
}

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  marginRight: '30px',
  width: calcSizeBasedInVhScale(250),

  [theme.breakpoints.down('sm')]: {
    marginRight: '15px',
    width: '100%',
    maxWidth: 300,
  },
  [theme.breakpoints.down(700)]: {
    width: '150px',
  },
}));

export const ContainerLarge = styled(Container)(() => ({
  width: calcSizeBasedInVhScale(320),
}));

export const ContainerHorizontal = styled('div')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  marginRight: '30px',
  width: '320px',
  flexShrink: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginRight: 0,
    marginBottom: '20px',
  },
}));

export const ContainerPodcastPlaylists = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  width: '100%',
  marginRight: 32,
});

export const HeaderPlaylists = styled('div')(({ theme }) => ({
  width: 720,
  marginRight: 100,
  paddingBottom: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    minWidth: 350,
    marginRight: 0,
    marginBottom: 0,
    paddingBottom: 28,
  },
}));

export const HeaderPlaylistsImage = styled('div')(({ theme }) => ({
  borderRadius: 13,
  width: 225,
  height: 225,
  '& > div': {
    height: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    width: 80,
    height: 80,
  },
}));

export const HeaderPlaylistsImageItem = styled(Image)({
  borderRadius: 13,
  width: '100%',
  height: '100%',
});

export const HeaderPlaylistsContent = styled('div')(({ theme }) => ({
  width: '65%',
  '& p': {
    fontSize: '1.5rem',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 100px)',
  },
}));

export const HeaderPlaylistsContentTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingBottom: 4,
  [theme.breakpoints.up(900)]: {
    paddingBottom: 30,
  },
  '& svg': {
    width: 30,
    margin: '4px 12px 0 0',
    color: '#fff',
    transition: 'all 0.5s ease',
    '& .playlistBlackIcon, .playlistWhiteIcon': {
      transition: 'all 0.5s ease',
    },
    '&:hover': {
      cursor: 'pointer',
      '& .playlistWhiteIcon': {
        fill: '#010109',
      },
      '& .playlistBlackIcon': {
        fill: '#fff',
      },
    },
    [theme.breakpoints.up(900)]: {
      width: 60,
    },
  },
  '& h3': {
    fontSize: '2.5rem',
    fontWeight: 700,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
}));

export const ListPlaylistEpisodes = styled('div')(({ theme }) => ({
  width: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  height: 300,
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar': {
    width: 5,
    height: 78,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#BFBFBF',
    border: 'solid 1px #BFBFBF',
    borderRadius: 50,
  },
  '& svg': {
    width: 30,
    marginRight: 20,
    color: '#fff',
    transition: 'all 0.5s ease',
    '& .playlistBlackIcon, .playlistWhiteIcon': {
      transition: 'all 0.5s ease',
    },
    '&:hover': {
      cursor: 'pointer',
      '& .playlistWhiteIcon': {
        fill: '#010109',
      },
      '& .playlistBlackIcon': {
        fill: '#fff',
      },
    },
    [theme.breakpoints.up(900)]: {
      margin: '4px 12px 0 0',
    },
  },
}));

export const ListPlaylistEpisodesItem = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& p': {
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.69rem',
    },
  },
}));

export const BackgroundImg = styled('div')(({ theme }) => ({
  width: calcSizeBasedInVhScale(250),
  height: calcSizeBasedInVhScale(250),
  borderRadius: '13px',
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.11)',
  position: 'relative',
  marginBottom: '12px',
  [theme.breakpoints.down(700)]: {
    width: '150px',
    height: '150px',
  },
}));

export const BackgroundImgLarge = styled(BackgroundImg)(() => ({
  width: calcSizeBasedInVhScale(320),
  height: calcSizeBasedInVhScale(320),
}));

export const ContainerIconVideo = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
}));

export const BackgroundImgHorizontal = styled('div')(({ theme }) => ({
  width: '140px',
  height: '140px',
  objectFit: 'cover',
  borderRadius: '13px',
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.11)',
  [theme.breakpoints.down('sm')]: {
    width: '120px',
    height: '120px',
  },
}));

export const HorizontalInfo = styled('div')(() => ({
  marginLeft: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const Text = styled('p')(({ theme }) => ({
  display: '-webkit-box',
  fontFamily: 'Barlow',
  fontSize: '18px',
  fontWeight: 600,
  margin: 0,
  WebkitLineClamp: 3,
  '-webkit-box-orient': 'vertical',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    fontWeight: 'normal',
  },
}));

export const TitleHorizontal = styled('p')(
  ({ theme, webserieStyle }: HorizontalText) => ({
    fontFamily: 'Barlow',
    fontSize: '16px',
    margin: 0,

    fontWeight: webserieStyle ? 'bold' : 'normal',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  }),
);

export const DescriptionHorizontal = styled('p')(
  ({ theme }: HorizontalText) => ({
    display: '-webkit-box',
    WebkitLineClamp: 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
    fontFamily: 'Barlow',
    fontSize: '14px',
    margin: 0,

    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  }),
);

export const VideoMask = styled('div')(() => ({
  '& svg': {
    width: '45px',
  },
  justifyContent: 'center',
  alignItems: 'center',
}));

export const VideoMaskHorizontal = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  width: '140px',
  height: '140px',
  borderRadius: '13px',
  top: -140,

  '& svg': {
    width: '45px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '120px',
    height: '120px',
    top: -120,
  },
}));

export const RowDetails = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 6px 0',

  '& svg': {
    height: 15,
    width: 15,
    marginRight: 8,
    '& path.a': {
      fill: '#000000',
    },
  },
  '& span': {
    fontFamily: 'Barlow',
    fontSize: '14px',
    marginLeft: '0px',
  },

  '& svg.noRated': {
    height: '22px',
    stroke: '#818181',
    color: 'transparent',
    margin: '0 6px 0 12px',
  },
  '& svg.rated': {
    height: '22px',
    stroke: '#FAB403',
    color: 'transparent',
    margin: '0 6px 0 12px',
  },
});

export const RowDetailsLarge = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 6px 0',

  '& svg': {
    height: 15,
    width: 15,
    marginRight: 8,
    color: 'transparent',
    '& path.a': {
      fill: '#000000',
    },
  },

  '& span': {
    fontFamily: 'Barlow',
  },

  '& svg.noRated': {
    stroke: '#818181',
  },
  '& svg.rated': {
    stroke: '#FAB403',
  },
}));

export const ContainerFavorite = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 5,
  top: 5,

  [theme.breakpoints.down('sm')]: {
    right: 15,
    top: 5,
  },
}));

export const ContainerFavoriteHorizontal = styled('div')({
  position: 'absolute',
  right: 5,
  top: 5,
});

export const Img = styled(Image)({
  borderRadius: '13px',
});

export const Episode = styled('p')(({ theme }) => ({
  margin: '15px 0px',
  fontSize: '14px',
  [theme.breakpoints.down('sm')]: {
    margin: '5px 0px',
    fontSize: '14px',
  },
}));

export const Dot = styled('span')(() => ({
  fontSize: '18px',
}));

export const DotLarge = styled('span')(() => ({
  fontSize: '18px !important',
  margin: '0 2px !important',
}));

export const EpisodeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 6,
  alignItems: 'center',
  overflow: 'hidden',
  maxWidth: calcSizeBasedInVhScale(311),

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: 150,
  },
}));

export const EpisodeText = styled('span')(() => ({
  minWidth: 70,
  display: '-webkit-box',
  WebkitLineClamp: 1,
  '-webkit-box-orient': 'vertical',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  overflow: 'hidden',
}));

export const DescriptionText = styled('span')(() => ({
  fontSize: 14,
  display: '-webkit-box',
  WebkitLineClamp: 3,
  '-webkit-box-orient': 'vertical',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  overflow: 'hidden',
}));

export const InfoText = styled('span')(() => ({
  fontSize: 14,
  display: '-webkit-box',
  WebkitLineClamp: 1,
  '-webkit-box-orient': 'vertical',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  overflow: 'hidden',
}));

export const PlaylistFavoriteContainer = styled('div')(() => ({
  position: 'absolute',
  top: 15,
  right: 15,
}));
