import { memo } from 'react';

import Favorite from '../../../../assets/svg/favorite.svg';
import { ContainerMobile, useStyles } from '../styles';

export interface MobileButtonProps {
  toggle: boolean;
  disableButton: boolean;
  handleFavorite(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  theme?: 'white' | 'dark' | 'superdark';
}

const MobileButton: React.FC<MobileButtonProps> = ({
  toggle,
  handleFavorite,
  disableButton,
  theme = 'white',
}) => {
  const themes = useStyles({ toggle });

  return (
    <ContainerMobile
      aria-label="favoritar"
      onClick={handleFavorite}
      disabled={disableButton}
      className={themes[theme]}
    >
      <Favorite />
    </ContainerMobile>
  );
};

export default memo(MobileButton);
