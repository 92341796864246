import { useMemo } from 'react';

import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import {
  Container,
  RegulamentationLink,
  ContainerCrown,
  LabelNivel,
} from './styles';

import ChallegeItem from './ChallegeItem';
import DashedDivider from '../../DashedDivider';

import { ILevel } from '..';

interface ChallengesProps {
  levels: ILevel[];
  totalIndications: number;
}

const Challenges: React.FC<ChallengesProps> = ({
  levels,
  totalIndications,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentLevel = useMemo(() => {
    if (totalIndications >= 50) {
      return levels[levels.length - 1];
    }
    const nextLevel = levels.findIndex(
      level => level.minIndications > totalIndications,
    );
    if (nextLevel === 0 || nextLevel === -1) return levels[0];
    return levels[nextLevel - 1];
  }, [levels, totalIndications]);

  return (
    <Container>
      {isMobile &&
        levels.map((item, index) => (
          <>
            <ChallegeItem key={index.toString()} {...item} />
            {index < levels.length - 1 && <DashedDivider />}
          </>
        ))}
      {!isMobile && (
        <>
          <DashedDivider />
          <Box padding="10px" display="flex">
            <Box width="50%" padding="0 10px">
              <ContainerCrown>
                <LabelNivel>Seu nível atual</LabelNivel>
                {currentLevel.icon}
              </ContainerCrown>
              <DashedDivider />
              <ChallegeItem {...levels[1]} />
              <DashedDivider />
              <ChallegeItem {...levels[3]} />
            </Box>
            <DashedDivider orientation="vertical" />
            <Box width="50%" padding="0 10px">
              <ChallegeItem {...levels[0]} />
              <DashedDivider />
              <ChallegeItem {...levels[2]} />
              <DashedDivider />
              <ChallegeItem {...levels[4]} />
            </Box>
          </Box>
        </>
      )}
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Box>
          <RegulamentationLink
            href={`${process.env.NEXT_PUBLIC_CAMPAIGN_REGULATION_URI}/politica-do-regulamento`}
            target="_blank"
          >
            {`Acessar o regulamento do sorteio >`}
          </RegulamentationLink>
        </Box>
        <Box marginLeft={!isMobile ? '83%' : '65%'}>
          <RegulamentationLink
            href={`${process.env.NEXT_PUBLIC_CAMPAIGN_REGULATION_URI}/politica-de-privacidade`}
            target="_blank"
          >
            {`Política de privacidade >`}
          </RegulamentationLink>
        </Box>
      </Box>
    </Container>
  );
};

export default Challenges;
