const Course: React.FC = () => {
  return (
    <svg width="35" height="28.254" viewBox="0 0 35 28.254">
      <path
        className="a"
        d="M34.668,29.107V18.983c1.041-.933.754-2.118-.969-2.908L22.066,10.618A9.114,9.114,0,0,0,18.261,9.9a9.719,9.719,0,0,0-3.806.718L2.788,16.039c-2.082.969-2.118,2.585,0,3.554l3.841,1.8V29.61l.036.323c.144.467,1.544,4.488,11.56,4.488s11.452-4.021,11.56-4.488l.072-8.544,2.334-1.077v8.832a7.132,7.132,0,0,0-1.58,4.093,7.494,7.494,0,0,0,1.9,4.524,1.167,1.167,0,0,0,.9.395,1.285,1.285,0,0,0,.9-.395,7.25,7.25,0,0,0,1.9-4.524A6.725,6.725,0,0,0,34.668,29.107Zm-7.252.251c-.323.539-2.082,2.621-9.155,2.621-7.18,0-8.868-2.154-9.155-2.621V22.537l5.349,2.477a9.114,9.114,0,0,0,3.806.718,9.719,9.719,0,0,0,3.806-.718l5.349-2.477Zm-6.39-6.534a6.916,6.916,0,0,1-2.764.5,6.916,6.916,0,0,1-2.764-.5L4.8,17.834l10.7-4.99a6.916,6.916,0,0,1,2.764-.5,6.916,6.916,0,0,1,2.764.5l10.663,4.99ZM33.447,34.672a3.848,3.848,0,0,1-.359-1.436,3.268,3.268,0,0,1,.359-1.436,3.848,3.848,0,0,1,.359,1.436A4.688,4.688,0,0,1,33.447,34.672Z"
        transform="translate(-1.212 -9.9)"
      />
    </svg>
  );
};

export default Course;
