import { useEffect } from 'react';
import { useRouter } from 'next/router';

interface Props {
  url: string;
}

const Redirect: React.FC<Props> = ({ url }) => {
  const router = useRouter();

  useEffect(() => {
    router.push(url);
  }, [router, url]);

  return <p style={{ margin: '20px' }}>Redirecionando...</p>;
};

export default Redirect;
