import { styled } from '@material-ui/core/styles';

export const FollowText = styled('footer')(({ theme }) => ({
  fontSize: 13,
  fontFamily: 'Barlow',
  letterSpacing: '1px',
  lineHeight: '16px',
  color: theme.palette.common.white,
  margin: 0,
  fontWeight: 500,
  padding: theme.spacing(0, 1),
  textAlign: 'center',
}));

export const Container = styled('footer')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(5),
  backgroundColor: '#001735',
  flexDirection: 'column',
  '& svg': {
    width: 127,
    marginBottom: theme.spacing(2),

    '& path.a': {
      fill: theme.palette.common.white,
    },
    '& circle': {
      fill: theme.palette.common.white,
    },
    '& g circle.a': {
      fill: theme.palette.primary.main,
    },
  },
  '& p, span': {
    fontSize: 13,
    fontFamily: 'Barlow',
    lineHeight: '16px',
    color: theme.palette.common.white,
    listStyleType: 'none',
    margin: 0,
    padding: theme.spacing(0, 1),
    textAlign: 'center',
  },
}));
