import { useRouter } from 'next/router';
import { useMemo } from 'react';
import copy from 'copy-to-clipboard';
import {
  WhatsappShareButton,
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import {
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsAppIcon,
  CopyLinkIcon,
} from '@components/core/Icons';
import { useSnackbar } from '@hooks/snackbar';
import { Area, LinkButton } from './styles';

const Share: React.FC = () => {
  const { asPath } = useRouter();
  const { showSnackbar } = useSnackbar();

  const currentURL = useMemo(
    () =>
      process.env.NEXT_PUBLIC_IS_PRODUCTION
        ? process.env.NEXT_PUBLIC_APP + asPath
        : 'https://startse.com',
    [asPath],
  );

  const copyLink = () => {
    copy(currentURL);
    showSnackbar({
      message: 'Link copiado para a área de transferencia',
      type: 'success',
    });
  };

  return (
    <Area>
      <WhatsappShareButton url={currentURL}>
        <WhatsAppIcon fontSize="large" />
      </WhatsappShareButton>
      <TelegramShareButton url={currentURL}>
        <TelegramIcon fontSize="large" />
      </TelegramShareButton>
      <FacebookShareButton url={currentURL}>
        <FacebookIcon fontSize="large" />
      </FacebookShareButton>
      <TwitterShareButton url={currentURL}>
        <TwitterIcon fontSize="large" />
      </TwitterShareButton>
      <LinkedinShareButton url={currentURL}>
        <LinkedinIcon fontSize="large" />
      </LinkedinShareButton>
      <LinkButton onClick={copyLink}>
        <CopyLinkIcon />
      </LinkButton>
    </Area>
  );
};

export default Share;
