const ProgramIcon: React.FC = () => {
  return (
    <svg width="30.076" height="30.076" viewBox="0 0 30.076 30.076">
      <g transform="translate(2.507 5.639)">
        <path
          className="a"
          fill="#acb1c0"
          d="M5.393,19.287a5.44,5.44,0,0,1-3.9-1.464A5.145,5.145,0,0,1,0,14.039V5.248A5.145,5.145,0,0,1,1.491,1.464,5.44,5.44,0,0,1,5.393,0H13.25a5.438,5.438,0,0,1,3.9,1.464,5.026,5.026,0,0,1,1.473,3.278l3.317-1.356a2.266,2.266,0,0,1,3.115,1.922l.007.177V13.8a2.266,2.266,0,0,1-2.956,2.158l-.167-.06-3.316-1.356a5.028,5.028,0,0,1-1.474,3.277,5.436,5.436,0,0,1-3.9,1.465ZM1.88,5.248v8.792a3.194,3.194,0,0,0,3.513,3.368H13.25a3.194,3.194,0,0,0,3.513-3.368v-.872c0-.01,0-.019,0-.029V6.157q0-.024,0-.047V5.248A3.194,3.194,0,0,0,13.25,1.88H5.393A3.194,3.194,0,0,0,1.88,5.248Zm20.77,8.913a.386.386,0,0,0,.522-.27l.01-.088V5.485a.386.386,0,0,0-.447-.382l-.085.024L18.643,6.765v5.757Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export default ProgramIcon;
