import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Router from 'next/router';

import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import theme from '@styles/theme';
import NProgress from 'nprogress';
import { Login, OneSignal } from '@components/core';
import EditProfile from '@components/core/EditProfile';
import Completed from '@components/core/Completed';
import '@styles/custom/nprogress.css';
import AppContext from '@hooks/app';
import config from 'config/seo.json';

import 'swiper/swiper.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'swiper/components/navigation/navigation.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'swiper/components/pagination/pagination.scss';
import dynamic from 'next/dynamic';

import Prompt from '@components/core/Prompt';
import PodcastPlayer from '@components/core/PodcastPlayer';

const Header = dynamic(() => import('@components/core/Header'));

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
});

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    dayjs.locale('pt-br');

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <DefaultSeo {...config} />
      <AppContext>
        <OneSignal />
        <div
          style={{
            display: 'grid',
            gridTemplateRows: '1fr auto',
            gridTemplateColumns: '1fr',
            height: '100vh',
          }}
        >
          <div
            id="scrollableDiv"
            style={{ overflow: 'hidden auto', position: 'relative' }}
          >
            <Header>
              <ThemeProvider theme={theme}>
                <Completed />
                <CssBaseline />
                <Component {...pageProps} />
                <Login />
                <Prompt />
                <EditProfile />
              </ThemeProvider>
            </Header>
          </div>
          <ThemeProvider theme={theme}>
            <PodcastPlayer />
          </ThemeProvider>
        </div>
      </AppContext>
    </>
  );
};

export default MyApp;
