import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const SliderRightIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    className="startse-icon"
    viewBox="0 0 16 16"
    fontSize="small"
    {...props}
  >
    <g transform="translate(0.5 15.883) rotate(-90)">
      <path
        d="M7.691,15.383a7.691,7.691,0,1,1,7.691-7.691A7.7,7.7,0,0,1,7.691,15.383ZM4.1,5.641a.513.513,0,0,0-.363.875l3.589,3.589a.513.513,0,0,0,.725,0l3.589-3.589a.513.513,0,0,0-.725-.725L7.691,9.018,4.465,5.791A.511.511,0,0,0,4.1,5.641Z"
        transform="translate(0 0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </SvgIcon>
);
export default SliderRightIcon;
