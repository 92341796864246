import { useCallback, memo, useMemo } from 'react';
import { useRouter } from 'next/router';

import { isMobile } from 'react-device-detect';
import SwiperCore from 'swiper';

import { ICardProps } from '@components/core/CardContent';
import iconTime from '@assets/png/time.webp';
import { Favorite } from '@components/core';
import PlayIcon from '../../../../assets/svg/watch-stories.svg';
import ArticlesIcon from '../../../../assets/svg/read-stories.svg';
import PodcastIcon from '../../../../assets/svg/listen-podcast.svg';
import {
  Button,
  Container,
  BackdropBottom,
  BackdropTop,
  Image,
  NextStory,
  PrevStory,
} from './styles';
import Header from '../Header';
import { StoriesProps } from '..';

interface IContent extends Pick<StoriesProps, 'handleClose'> {
  story: ICardProps;
  swiper?: SwiperCore;
}

const Content: React.FC<IContent> = ({ story, handleClose, swiper }) => {
  const router = useRouter();

  const icons = useMemo(
    () => ({
      article: <ArticlesIcon />,
      video: <PlayIcon />,
      podcast: <PodcastIcon />,
    }),
    [],
  );

  const handleIcon = useCallback(
    (type: string) => {
      if (type) {
        return icons[type];
      }
      return undefined;
    },
    [icons],
  );

  const handleSelectStory = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      router.push(story?.url);
    },
    [router, story],
  );

  return (
    <Container
      style={{
        background: `url(${story?.background}) no-repeat center`,
      }}
    >
      <BackdropTop />
      <BackdropBottom />
      {isMobile && (
        <>
          <NextStory onClick={() => swiper.slideNext()} />
          <PrevStory onClick={() => swiper.slidePrev()} />
          <Header handleClose={handleClose} />
        </>
      )}
      <section>
        <div>
          <Favorite
            buttonType="mobile"
            contentId={story?.id}
            type={story?.type}
          />
        </div>
        <h1>{story?.title}</h1>
        <Image width="30px" height="30px" alt="Time Icon" src={iconTime} />
        <p>{story?.time}</p>
        <Button onClick={handleSelectStory}>{handleIcon(story?.type)}</Button>
      </section>
    </Container>
  );
};

export default memo(Content);
