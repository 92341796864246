import { AdLoginProvider } from './adLogin';
import { UserProvider } from './user';
import { AuthProvider } from './auth';
import { LoadingProvider } from './loading';
import { SnackbarProvider } from './snackbar';
import { TrackingProvider } from './tracking';
import { FavoriteProvider } from './favorite';
import { EventProvider } from './event';
import { PromptProvider } from './prompt';
import { PodcastProvider } from './podcast';
import { FollowProvider } from './follow';

const AppProvider: React.FC = ({ children }) => {
  return (
    <TrackingProvider>
      <PromptProvider>
        <LoadingProvider>
          <UserProvider>
            <AuthProvider>
              <EventProvider>
                <FavoriteProvider>
                  <FollowProvider>
                    <SnackbarProvider>
                      <PodcastProvider>
                        <AdLoginProvider>{children}</AdLoginProvider>
                      </PodcastProvider>
                    </SnackbarProvider>
                  </FollowProvider>
                </FavoriteProvider>
              </EventProvider>
            </AuthProvider>
          </UserProvider>
        </LoadingProvider>
      </PromptProvider>
    </TrackingProvider>
  );
};

export default AppProvider;
