import { ISaveOptIn } from '@models/notifications.interface';
import axios, { AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NOTIFICATIONS_API,
});

export const saveOptIn = ({
  playerId,
  userId,
  provider = 'oneSignal',
}: ISaveOptIn): Promise<AxiosResponse> =>
  api.post('/notifications/optin', {
    playerId,
    userId,
    provider,
  });
