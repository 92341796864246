import { Box, styled } from '@material-ui/core';

export const Container = styled(Box)({
  width: '100%',
  height: '100vh',
  background: '#333333',

  '& > div': {
    height: 'calc(100vh - 114px)!important',
  },
  '& > div div': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const Receptacle = styled(Box)({
  margin: '0 auto',
  transition: 'all 0.3s ease',
  position: 'relative',
  width: '320px',
});

export const NavigationButton = styled('div')({
  background: '#818181',
  color: 'white',
  borderRadius: '50%',
  width: 50,
  height: 50,

  '&::after': {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

export const ContainerPagination = styled('footer')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,

  '& div span': {
    background: 'white',
    margin: 2,
  },
});
