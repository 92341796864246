import { createContext, useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { IEvent } from '@models/event.interface';
import { EventService } from '@services/content';
import { ActivityService } from '@services/conferences';

interface IEventContextData {
  liveEvent: IEvent;
  activities: any[];
  isLoadingActivity: boolean;
}

const EventContext = createContext<IEventContextData>({} as IEventContextData);

const EventProvider: React.FC = ({ children }) => {
  const [liveEvent, setLiveEvent] = useState({} as IEvent);
  const [activities, setActivities] = useState([] as any[]);
  const [isLoadingActivity, setIsLoadingActivity] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoadingActivity(true);
        const activitiesResponse = await ActivityService.findByEvent(
          liveEvent.id,
        );
        if (!activitiesResponse || activitiesResponse.length < 1) return;
        const currentActivities = activitiesResponse.filter(
          a =>
            dayjs(a.endedAt).isAfter(dayjs()) &&
            dayjs(a.startedAt).isBefore(dayjs()) &&
            a.title,
        );

        if (!currentActivities || currentActivities.length < 1) return;

        setActivities(currentActivities);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setIsLoadingActivity(false);
      }
    };

    if (!liveEvent || !liveEvent.id) return;
    fetch();
  }, [liveEvent]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const events = await EventService.findAll();
        const currentEvent = events.find(
          e =>
            dayjs(e.endedAt).isAfter(dayjs()) &&
            dayjs(e.startedAt).isBefore(dayjs()) &&
            e.imageCard,
        );
        if (currentEvent && currentEvent.id) setLiveEvent(currentEvent);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    fetch();
  }, []);

  return (
    <EventContext.Provider value={{ liveEvent, activities, isLoadingActivity }}>
      {children}
    </EventContext.Provider>
  );
};

function useEvent(): IEventContextData {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error('Event must be used within a EventProvider');
  }

  return context;
}

export { useEvent, EventProvider };
