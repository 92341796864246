import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ReplyIcon from '@material-ui/icons/Reply';

const useStyles = makeStyles({
  root: {
    color: '#333333',
    backgroundColor: 'rgba(255, 255, 255)',
    padding: '4px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255)',
    },
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    '& svg': {
      transform: 'rotateY(180deg)',
    },
    borderRadius: '50%',
  },
});

export interface IShareButton {
  onClick?: (event: React.MouseEvent) => void;
  transparent?: boolean;
}

const ShareButton: React.FC<IShareButton> = ({ onClick, transparent }) => {
  const { root } = useStyles();
  return (
    <IconButton
      aria-label="Compartilhar"
      className={root}
      onClick={e => (onClick ? onClick(e) : null)}
      style={{
        backgroundColor: transparent ? 'transparent' : '#fff',
      }}
    >
      <ReplyIcon />
    </IconButton>
  );
};

export default ShareButton;
