import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export interface IBackButton {
  href: string;
  backgroundColor?: string;
  backgroundHover?: string;
  colorIcon?: string;
  width?: string;
  height?: string;
}

let isButton = false;

const BackButton: React.FC<IBackButton> = ({
  href,
  backgroundColor,
  backgroundHover,
  colorIcon,
  width,
  height,
}) => {
  const useStyles = makeStyles({
    root: {
      marginLeft: 0,
      backgroundColor: backgroundColor || 'rgba(255, 255, 255, 0.4)',
      padding: '4px',
      '&:hover': {
        backgroundColor: backgroundHover || 'rgba(255, 255, 255, 0.4)',
      },
      '&:active': {
        backgroundColor: backgroundColor || 'rgba(255, 255, 255, 0.6)',
      },
      '&:focus': {
        backgroundColor: backgroundColor || 'rgba(255, 255, 255, 0.6)',
      },
      borderRadius: '50%',
    },
  });
  const router = useRouter();
  const { root } = useStyles();

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== href && isButton) {
        window.location.href = href;
        isButton = false;
        return false;
      }
      return true;
    });
  }, [href, router]);

  const goBack = () => {
    isButton = true;
    if (window?.history?.state?.idx > 0) {
      router.back();
    } else {
      router.replace(href);
    }
  };

  return (
    <IconButton
      className={root}
      aria-label="voltar"
      onClick={goBack}
      style={{ width: width || 'auto', height: height || 'auto' }}
    >
      <NavigateBeforeIcon style={{ color: colorIcon || '#000000' }} />
    </IconButton>
  );
};

export default BackButton;
