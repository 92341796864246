import { memo } from 'react';
import { useRouter } from 'next/router';

import { Box, Tooltip } from '@material-ui/core';

import { useTracking } from '@hooks/tracking';
import { usePodcastContext } from '@hooks/podcast';

import { ITrack } from '@models/player.interface';

import { ContentType } from '@utils/contentType';
import { Favorite } from '@components/core';
import PlayIcon from '../../../../assets/svg/play_podcast.svg';

import * as Styled from '../styles';

export interface ICardPlaylistProps {
  id?: string;
  image?: string;
  slug?: string;
  title?: string;
  description?: string;
  episodes?: ITrack[];
  showModalConfirm?: boolean;
}

const Card: React.FC<ICardPlaylistProps> = ({
  id,
  slug,
  showModalConfirm,
  image,
  title,
  description,
  episodes,
}) => {
  const router = useRouter();
  const { track } = useTracking();

  const { setTrackQueue, setTrackIndex, setIsPlaying, setShowPlayer } =
    usePodcastContext();

  const handleContainerClick = () => {
    if (router.pathname.includes('meus-favoritos')) {
      track('Clicked in favorite content', {});
    }
  };

  return (
    <Styled.ContainerPodcastPlaylists onClick={handleContainerClick}>
      <Styled.HeaderPlaylists>
        <Styled.HeaderPlaylistsImage>
          <Styled.HeaderPlaylistsImageItem
            src={image || '/share.webp'}
            width=""
            height=""
            objectFit="cover"
            alt={title}
            quality="50"
          />
        </Styled.HeaderPlaylistsImage>
        <Tooltip title="Favoritar">
          <Styled.PlaylistFavoriteContainer>
            <Favorite
              buttonType="mobile"
              contentId={id}
              contentSlug={slug}
              type={ContentType.podcastPlaylist}
              theme="superdark"
              showModalConfirm={showModalConfirm}
              onClick={() => {
                track('Click on the favorite playlist podcast', {
                  podcastName: slug,
                  playlistName: title,
                });
              }}
            />
          </Styled.PlaylistFavoriteContainer>
        </Tooltip>
        <Styled.HeaderPlaylistsContent>
          <Styled.HeaderPlaylistsContentTitle>
            <Box
              onClick={() => {
                setTrackQueue(episodes);
                setTrackIndex(0);
                setIsPlaying(true);
                setShowPlayer(true);
                track('Click on the playlist player podcast', {
                  podcastName: slug,
                  playlistName: title,
                });
              }}
            >
              <PlayIcon />
            </Box>
            <h3>{title}</h3>
          </Styled.HeaderPlaylistsContentTitle>
          <p>{description}</p>
        </Styled.HeaderPlaylistsContent>
      </Styled.HeaderPlaylists>

      <Styled.ListPlaylistEpisodes>
        {episodes?.map((episode, index) => {
          return (
            <Styled.ListPlaylistEpisodesItem>
              <Box
                onClick={() => {
                  setTrackQueue(episodes);
                  setTrackIndex(index);
                  setIsPlaying(true);
                  setShowPlayer(true);
                  track('Click on the playlist player podcast', {
                    podcastName: episode.authorSlug,
                    program: episode.title,
                    category_program: episode.category,
                    playlistName: title,
                  });
                }}
              >
                <PlayIcon />
              </Box>
              <p>{episode.title}</p>
            </Styled.ListPlaylistEpisodesItem>
          );
        })}
      </Styled.ListPlaylistEpisodes>
    </Styled.ContainerPodcastPlaylists>
  );
};

export default memo(Card);
