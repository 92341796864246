import { IFollowContextData } from '@hooks/follow';
import IFollow from '@models/follow.interface';
import api from '../api';

export const findAll = async (
  userId: string,
  limit = 10,
  offset = 0,
  type = '',
): Promise<IFollowContextData> => {
  const params = new URLSearchParams();
  params.append('limit', String(limit));
  params.append('offset', String(offset));
  params.append('userId', String(userId));
  if (type !== '') {
    params.append('type', type);
  }
  try {
    const followsResponse = await api.get<IFollowContextData>('followers', {
      params,
    });
    return followsResponse.data;
  } catch {
    throw new Error('Failed to get user follows');
  }
};

export const create = async (
  userId: string,
  contentId: string,
  type: string,
): Promise<IFollow> => {
  try {
    const followsResponse = await api.post<IFollow>('followers', {
      userId,
      contentId,
      type,
    });
    return followsResponse.data;
  } catch {
    throw new Error('Failed to create user follows');
  }
};

export const remove = async (followId: string): Promise<IFollow[]> => {
  try {
    const followsResponse = await api.delete(`followers/${followId}`);
    return followsResponse.data;
  } catch {
    throw new Error('Failed to delete user follows');
  }
};
