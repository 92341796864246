import { withStyles } from '@material-ui/core/styles';
import MuiBackdrop from '@material-ui/core/Backdrop';

const Backdrop = withStyles(() => ({
  root: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'transparent',
  },
}))(MuiBackdrop);

export default Backdrop;
