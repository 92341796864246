const VideoIcons: React.FC = () => {
  return (
    <svg width="30.076" height="30.076" viewBox="0 0 30.076 30.076">
      <g transform="translate(2.506 2.506)">
        <path
          className="a"
          fill="#acb1c0"
          d="M0,12.531A12.531,12.531,0,1,1,12.531,25.063,12.545,12.545,0,0,1,0,12.531Zm1.748,0A10.783,10.783,0,1,0,12.531,1.748,10.8,10.8,0,0,0,1.748,12.531Zm9.2,4.633a1.473,1.473,0,0,1-1.3-.788,3.854,3.854,0,0,1-.217-.735l-.063-.328a20.043,20.043,0,0,1-.178-2.94l0-.362a17.174,17.174,0,0,1,.2-2.691,6.1,6.1,0,0,1,.21-.849,1.448,1.448,0,0,1,.655-.72,1.43,1.43,0,0,1,.679-.176h.047l.13.012a4.27,4.27,0,0,1,.837.254,17.78,17.78,0,0,1,4.761,3.213l.281.3.1.115a1.456,1.456,0,0,1,.032,1.761,3,3,0,0,1-.237.27l-.337.352a18.806,18.806,0,0,1-4.473,3A4.058,4.058,0,0,1,11,17.165Zm.176-7.593-.042.249a19.212,19.212,0,0,0-.14,2.551l0,.349a16.991,16.991,0,0,0,.153,2.308l.061.294.24-.088a16.721,16.721,0,0,0,4.048-2.758l.1-.108-.265-.274a16.879,16.879,0,0,0-3.97-2.624l-.155-.056Z"
        />
      </g>
    </svg>
  );
};

export default VideoIcons;
