/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useMemo } from 'react';
import NextLink from 'next/link';

import { WhiteSpace } from '@components/ui';
import StartseLogo from '../../../assets/svg/startse-logo.svg';
import FacebookLogo from '../../../assets/svg/facebook.svg';
import LinkedinLogo from '../../../assets/svg/linkedin.svg';
import InstagramLogo from '../../../assets/svg/instagram.svg';
import TwitterLogo from '../../../assets/svg/twitter.svg';
import YoutubeLogo from '../../../assets/svg/youtube.svg';

import { Container, FollowText } from './styles';

const SocialFooter: React.FC = () => {
  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      margin: theme.spacing(2, 1),
      padding: 0,
      '& svg': {
        width: '24px',
      },
    },
  });

  const { root } = useStyles();
  const socialMedia = useMemo(
    () => [
      {
        alt: 'facebook',
        icon: <FacebookLogo />,
        link: 'https://www.facebook.com/startseoficial',
      },
      {
        alt: 'linkedin',
        icon: <LinkedinLogo />,
        link: 'https://www.linkedin.com/school/startse/',
      },
      {
        alt: 'instagram',
        icon: <InstagramLogo />,
        link: 'https://www.instagram.com/startseoficial/',
      },
      {
        alt: 'twitter',
        icon: <TwitterLogo />,
        link: 'https://twitter.com/startseoficial',
      },
      {
        alt: 'youtube',
        icon: <YoutubeLogo />,
        link: 'https://www.youtube.com/startseoficial',
      },
    ],
    [],
  );
  return (
    <Container>
      <StartseLogo />
      <Box my={4}>
        <FollowText> Siga a StartSe nas Redes Sociais:</FollowText>
        <Box display="flex" my={1} maxWidth={282} mx="auto">
          {socialMedia.map(({ alt, icon, link }) => (
            <NextLink href={link} key={alt}>
              <a target="_blank" rel="noreferrer">
                <IconButton className={root}>{icon}</IconButton>
              </a>
            </NextLink>
          ))}
        </Box>
        <Box my={1} maxWidth={334} textAlign="center">
          <Typography variant="overline">
            <strong>VENDAS E ATENDIMENTO</strong>
          </Typography>
          <br />
          <Typography variant="overline">
            (DE SEGUNDA A SEXTA, DAS 10HS ÀS 18HS): (011) 94465-9807 /
            99593-1524 / 95075-9513 / 93341-2882
          </Typography>
          <WhiteSpace />
          <Typography variant="overline">
            <strong>E-MAILS:</strong>
          </Typography>
          <Typography>atendimento@startse.com</Typography>
          <Typography>dpo@startse.com</Typography>
          <WhiteSpace />
          <Typography variant="overline">
            STARTSE TURISMO DE NEGOCIOS LTDA
          </Typography>
          <Typography variant="overline">CPNJ: 32.291.526/0001-64</Typography>
          <WhiteSpace />
          <Typography variant="overline">
            STARTSE INFORMACOES E SISTEMAS S/A
          </Typography>
          <Typography variant="overline">CNPJ: 24.554.736/0002-50</Typography>
        </Box>
        <Box mt={8} textAlign="center">
          <p> Todos os direitos reservados</p>
          <p> @startse.com</p>
          <WhiteSpace />
          <p> São Paulo, Brasil</p>
          <p> Palo Alto, EUA</p>
          <p> Shanghai, China</p>
        </Box>
      </Box>
    </Container>
  );
};

export default SocialFooter;
