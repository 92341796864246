const PodcastIcon: React.FC = () => {
  return (
    <svg width="30.076" height="30.076" viewBox="0 0 30.076 30.076">
      <g transform="translate(5.013 2.506)">
        <path
          className="a"
          fill="#acb1c0"
          d="M9.112,24.249,9.1,24.12V21.486A10.166,10.166,0,0,1,0,11.276a.933.933,0,0,1,.923-.943.932.932,0,0,1,.921.943,8.275,8.275,0,0,0,8.18,8.366,8.276,8.276,0,0,0,8.183-8.366.922.922,0,1,1,1.844,0,10.167,10.167,0,0,1-9.1,10.209V24.12a.921.921,0,0,1-1.835.129Zm.758-7.437a5.49,5.49,0,0,1-5.422-5.544V5.544A5.49,5.49,0,0,1,9.87,0h.31A5.491,5.491,0,0,1,15.6,5.544v5.723a5.491,5.491,0,0,1-5.423,5.544ZM6.292,5.544v5.723A3.623,3.623,0,0,0,9.87,14.926h.31a3.624,3.624,0,0,0,3.579-3.659v-.159h-2.59a.944.944,0,0,1-.125-1.877l.125-.009h2.59V7.12H12.34a.944.944,0,0,1-.125-1.877l.125-.009h1.406A3.616,3.616,0,0,0,10.18,1.886H9.87A3.623,3.623,0,0,0,6.292,5.544Z"
        />
      </g>
    </svg>
  );
};

export default PodcastIcon;
