import { Button as MaterialButton, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 345,
  maxWidth: 324,
  margin: '20px',
  whiteSpace: 'normal',
  position: 'relative',
  borderRadius: 10,
  objectFit: 'contain',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    height: 442,
    maxWidth: 426,
  },
}));

export const Button = styled(MaterialButton)(({ theme }) => ({
  borderRadius: 50,
  height: 39,
  maxWidth: 205,
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    backgroundColor: '#18B7AF',
    '& span': {
      fontSize: 14,
    },
  },
}));
export const Backdrop = styled(MaterialButton)(({ theme }) => ({
  height: 345,
  [theme.breakpoints.up('md')]: {
    height: 442,
  },
  width: '100%',
  borderRadius: 10,
  background:
    'linear-gradient(to bottom, rgba(0,0,0,0) 25%, rgba(0,0,0,0.95) 100%)',
  position: 'absolute',
}));
export const Image = styled('img')({
  height: 345,
  width: '100%',
  objectFit: 'cover',
  borderRadius: 10,
  // backgroundPosition: 'bottom 0 right 0',
});
export const CardTitle = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  fontFamily: 'Barlow',
  fontSize: 25,
  lineHeight: '36px',
  fontWeight: 600,
  textTransform: 'capitalize',
  maxWidth: 165,
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    fontFamily: 'Barlow',
    fontSize: 35,
    lineHeight: '42px',
    textTransform: 'capitalize',
    maxWidth: 250,
    textAlign: 'center',
  },
}));
export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  padding: theme.spacing(2),
  height: '100%',
  maxHeight: '80%',
  margin: theme.spacing(2, 4),
  '& a': {
    textDecoration: 'none',
  },
  '& h4': {
    [theme.breakpoints.up('md')]: {
      lineHeight: '19.2px',
      fontSize: 19,
      color: theme.palette.common.white,
    },
    fontFamily: 'Barlow',
    lineHeight: '18.2px',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.common.white,
  },
  '& p': {
    fontFamily: 'Barlow',
    lineHeight: '19.2px',
    letterSpacing: 0,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.common.white,
  },
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'space-around',
}));
