import { styled, Theme } from '@material-ui/core';

interface PrimaryButtonProps {
  theme: Theme;
  width?: string;
  height?: string;
  backgroundColor?: string;
  color?: string;
  fontSize?: string;
  fontSizeSm?: string;
  fontWeight?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'bold'
    | 'normal'
    | 'bolder'
    | 'lighter';
}

export default styled('button')(
  ({
    theme,
    color,
    backgroundColor,
    fontSize,
    fontWeight,
    height,
    width,
    fontSizeSm,
  }: PrimaryButtonProps) => ({
    cursor: 'pointer',
    width: width || '100%',
    minHeight: height || '2.5em',
    border: 0,
    backgroundColor: backgroundColor || '#1e90ff',
    color: color || '#ffffff',
    fontSize: fontSize || '1.8vh',
    fontWeight: fontWeight || 'bold',
    borderRadius: '24px',
    fontFamily: 'Barlow',

    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizeSm || '14px',
    },
  }),
);
