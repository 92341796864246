import { styled } from '@material-ui/core/styles';

interface HeaderProps {
  nothiddenborder: boolean;
}

export const Header = styled('div')((props: HeaderProps) => ({
  padding: 20,
  borderBottom: props.nothiddenborder ? '1px solid #E0E0E0' : 'none',
  '@media screen and (min-width: 1100px)': {
    padding: '40px 38px 40px 40px',
  },
}));

export const TitleGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& a': {
    marginBottom: 10,
    color: '#0080ed',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const Title = styled('h1')(() => ({
  color: '#000000',
  fontFamily: 'Barlow',
  fontWeight: 'bold',
  fontSize: 24,
  margin: 0,
  marginBottom: '10px',
  '@media screen and (min-width: 1100px)': {
    fontSize: 28,
  },
}));

export const Subtitle = styled('h2')(() => ({
  color: '#000000',
  fontFamily: 'Barlow',
  fontWeight: 'normal',
  fontSize: 16,
  margin: 0,
  '@media screen and (min-width: 1100px)': {
    fontSize: '24px',
  },
}));
