import {
  Box,
  CircularProgress as MaterialCircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { calcSizeBasedInVhScale } from '@utils/metrics';

import { Label, useStyles, ContainerLabel } from './styles';

interface IProgress {
  percetage: number;
  totalIndications: number;
}
const CircularProgress: React.FC<IProgress> = ({
  percetage,
  totalIndications,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      position="relative"
      display="inline-flex"
      className="progress"
      my={isMobile ? calcSizeBasedInVhScale(20) : '0'}
    >
      <MaterialCircularProgress
        variant="determinate"
        value={100}
        thickness={5}
        size={isMobile ? 120 : calcSizeBasedInVhScale(190)}
        className={classes.bottom}
      />
      <MaterialCircularProgress
        variant="determinate"
        value={totalIndications <= 50 ? percetage : 100}
        color="primary"
        thickness={5}
        size={isMobile ? 120 : calcSizeBasedInVhScale(190)}
        className={classes.top}
      />
      <Box
        position="absolute"
        minWidth="100%"
        minHeight="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ContainerLabel>
          <Label>{totalIndications <= 50 ? totalIndications : '50+'}</Label>
          <span>{totalIndications <= 1 ? 'Indicação' : 'Indicações'}</span>
        </ContainerLabel>
      </Box>
    </Box>
  );
};

export default CircularProgress;
