import { Box, BoxProps } from '@material-ui/core';

interface IWhiteSpace extends BoxProps {
  space?: number;
}

const WhiteSpace: React.FC<IWhiteSpace> = ({ space = 1, ...rest }) => {
  return <Box {...rest} m={space} />;
};

export default WhiteSpace;
