import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles, styled, makeStyles } from '@material-ui/core/styles';

export const Dialog = withStyles(() => ({
  root: {
    margin: '0px',
    padding: '0px',
    borderRadius: '15px',
  },
  paper: {
    width: '100%',
    height: '800px',
    borderRadius: '15px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
}))(MuiDialog);

export const DialogContent = withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    borderRadius: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px !important',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(44px)',
    boxShadow: '0px 0px 60px #00000036',
    '@media screen and (max-width: 780px)': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
}))(MuiDialogContent);

export const useStyles = makeStyles({
  close: {
    zIndex: 99,
    position: 'absolute',
    top: '30px',
    right: '50px',
  },
});

export const Iframe = styled('iframe')(() => ({
  position: 'absolute',
  width: '100%',
  borderRadius: '15px',
  borderWidth: '0px',
  overflow: 'scroll !important',
}));
