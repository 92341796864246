import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlayerProps } from '../index';
import { Container } from '../styles';

const SambaPlayer: React.FC<PlayerProps> = ({ videoUrl }) => {
  const [latestLink, setLatestLink] = useState(videoUrl);
  const [loadPlayer, setLoadPlayer] = useState(false);
  const divId = 'sambaPlayer';

  const sambaKeys = useMemo(() => {
    const linkSplit = videoUrl.split('/');
    return [linkSplit[linkSplit.length - 2], linkSplit[linkSplit.length - 1]];
  }, [videoUrl]);

  const instancePlayer = useCallback(async () => {
    const playerConfig = {
      ph: sambaKeys[0],
      m: sambaKeys[1],
      playerParams: {
        wideScreen: true,
        captionTheme: ['ffffff', 'M', 'pt-br', true],
      },
    };
    const win = window as any;
    if (!win.playerSamba) {
      win.playerSamba = new win.SambaPlayer(divId, playerConfig);
    }
  }, [sambaKeys]);

  useEffect(() => {
    const win = window as any;
    if (
      win.playerSamba?.iframe?.src &&
      latestLink !== win.playerSamba?.iframe?.src &&
      !loadPlayer
    ) {
      win.playerSamba.iframe.remove();
      instancePlayer();
    }
    // eslint-disable-next-line
  }, [latestLink, loadPlayer]);

  useEffect(() => {
    const win = window as any;
    const script = document.createElement('script');
    setLatestLink(videoUrl);
    if (!win.SambaPlayer && !loadPlayer) {
      setLoadPlayer(true);
      script.src = 'https://player.sambatech.com.br/v3/samba.player.api.js';
      script.async = true;
      script.onload = () => {
        instancePlayer();
      };
      document.body.appendChild(script);
    } else if (win.playerSamba?.iframe?.src && !loadPlayer) {
      setLoadPlayer(true);
      win.playerSamba.iframe.remove();
      instancePlayer();
    } else if (!loadPlayer) {
      setLoadPlayer(true);
      instancePlayer();
    }
    // eslint-disable-next-line
  }, [videoUrl]);

  useEffect(() => {
    const win = window as any;
    return () => {
      win.SambaPlayer = undefined;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="plyr__video-embed" id="player">
      <div id={divId} style={{ width: '100%', height: '100%' }} />
    </Container>
  );
};
export default SambaPlayer;
