/* eslint-disable no-shadow */
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  Dispatch,
  useCallback,
} from 'react';

import IFollow from '@models/follow.interface';
import { FollowService } from '@services/content';
import { ActionMap } from '@utils/format';
import { useUser } from '@hooks/user';

export enum FollowTypes {
  Create = 'CREATE_FOLLOWS',
  Delete = 'DELETE_FOLLOWS',
  List = 'LIST_FOLLOWS',
}

interface IFollowContext {
  state: IFollowContextData;
  dispatch: Dispatch<FollowActions>;
}

export interface IFollowContextData {
  total: number;
  offset: number;
  limit: number;
  followers: IFollow[];
}

type FollowPayload = {
  [FollowTypes.Create]: IFollow;
  [FollowTypes.List]: IFollowContextData;
  [FollowTypes.Delete]: {
    followId: string;
  };
};

export type FollowActions =
  ActionMap<FollowPayload>[keyof ActionMap<FollowPayload>];

export const followReducer = (
  state: IFollowContextData,
  action: FollowActions,
): IFollowContextData => {
  switch (action.type) {
    case FollowTypes.Create:
      return {
        ...state,
        total: state.total + 1,
        followers: [action.payload, ...state.followers],
      };
    case FollowTypes.List:
      return {
        ...action.payload,
      };
    case FollowTypes.Delete:
      return {
        ...state,
        total: state.total - 1,
        followers: state.followers.filter(
          follow => follow.id !== action.payload.followId,
        ),
      };
    default:
      return state;
  }
};

const initialState = {
  total: 0,
  offset: 0,
  limit: 0,
  followers: [],
};

const FollowContext = createContext<{
  state: IFollowContextData;
  dispatch: Dispatch<FollowActions>;
}>({ state: initialState, dispatch: () => null });

const FollowProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(followReducer, initialState);
  const { user } = useUser();
  const limit = 9999;
  const offset = 0;

  const initialFetch = useCallback(async () => {
    const data = await FollowService.findAll(user._id, limit, offset);
    dispatch({
      type: FollowTypes.List,
      payload: data,
    });
  }, [user]);

  useEffect(() => {
    if (user && user?._id) {
      initialFetch();
    }
  }, [user, user?._id, initialFetch]);

  return (
    <FollowContext.Provider value={{ state, dispatch }}>
      {children}
    </FollowContext.Provider>
  );
};

function useFollows(): IFollowContext {
  const context = useContext(FollowContext);

  if (!context) {
    throw new Error('Follow must be used within a FollowProvider');
  }

  return context;
}

export { useFollows, FollowProvider };
