import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IUser } from '@utils/user';
import { useRouter } from 'next/router';
import { getEventAttendee } from '@services/squads';
import { IEvent } from '@models/event.interface';

interface IUserContextData {
  user: IUser;
  showEditProfile: boolean;
  setShowEditProfile(showEditProfile: boolean): void;
  setUser(user: IUser): void;
  showOnboarding(): void;
  userEvents: IEvent[];
  fetchEvents: () => void;
  updateUserEvents: (event: IEvent) => void;
}

const UserContext = createContext<IUserContextData>({} as IUserContextData);

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [userEvents, setUserEvents] = useState<IEvent[]>([]);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const { asPath } = useRouter();

  const currentURL = useMemo(
    () => process.env.NEXT_PUBLIC_APP + asPath,
    [asPath],
  );

  const showOnboarding = () => {
    if (!process.env.NEXT_PUBLIC_ONBOARDING) return;
    window.location.href = `${process.env.NEXT_PUBLIC_ONBOARDING}?hide[0]=profile&hide[1]=professional&hide[2]=card&contexting=true&noClose=true&redirect_to=${currentURL}`;
  };

  const fetchEvents = useCallback(async () => {
    if (user._id) {
      try {
        const rows = await getEventAttendee(user._id);
        setUserEvents(rows.map(row => row.event));
      } catch (err) {
        console.log(err);
      }
    }
  }, [user?._id]);

  const updateUserEvents = (event: IEvent) => {
    setUserEvents([...userEvents, event]);
  };

  useEffect(() => {
    if (user._id) fetchEvents();
  }, [fetchEvents, user?._id]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        showOnboarding,
        showEditProfile,
        setShowEditProfile,
        fetchEvents,
        userEvents,
        updateUserEvents,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUser(): IUserContextData {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('User must be used within a UserProvider');
  }

  return context;
}

export { useUser, UserProvider };
