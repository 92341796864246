const ShowcaseIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="61"
      fontSize="30"
      viewBox="0 0 68 61"
    >
      <g
        id="Group_6012"
        data-name="Group 6012"
        transform="translate(-21.005 -664.48)"
      >
        <g
          id="noun_Shopping_Bag_2133890"
          data-name="noun_Shopping Bag_2133890"
          transform="translate(35.83 675)"
        >
          <path
            id="showcase_icon"
            className="a"
            data-name="Path 25733"
            d="M16.765,272.129a5.009,5.009,0,0,0-5.007,5v.72H3.89a.715.715,0,0,0-.715.715v24.319a.715.715,0,0,0,.715.715H29.64a.715.715,0,0,0,.715-.715V278.566a.715.715,0,0,0-.715-.715H21.772v-.72a5.009,5.009,0,0,0-5.007-5Zm0,1.43a3.551,3.551,0,0,1,3.576,3.571v.72H13.189v-.72A3.551,3.551,0,0,1,16.765,273.56ZM4.606,279.282h7.153v2.135a.715.715,0,1,0,1.431,0v-2.135h7.153v2.135a.715.715,0,1,0,1.431,0v-2.135h7.153V302.17H4.606Z"
            transform="translate(2 -269.542)"
            fill="#acb1c0"
          />
        </g>
      </g>
    </svg>
  );
};

export default ShowcaseIcon;
