import { withStyles, styled } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { PaperProps } from '@material-ui/core/Paper';

export const AlertBody = styled('div')(() => ({
  width: '550px',
  maxWidth: '100%',
  height: '250px',
  backgroundColor: '#f7f7f7',
  borderRadius: '15px',
})) as React.FC<PaperProps>;

export const Button = withStyles(() => ({
  root: {
    flexGrow: 1,
    lineHeight: '18px',
    fontSize: '23px',
    padding: '25px',
    color: '#3f98ef',
    '&~button': {
      borderLeft: '1px solid #d9d9d9',
    },
  },
}))(ButtonBase);

export const Actions = withStyles(() => ({
  root: {
    justifyContent: 'center',
    padding: 0,
    borderTop: '1px solid #d9d9d9',
  },
}))(DialogActions);

export const BackdropProps = {
  style: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
};

export const Content = withStyles(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}))(DialogContent);

export const Text = withStyles(() => ({
  root: {
    color: '#000',
    fontSize: '23px',
    fontFamily: 'Barlow, sans-serif',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
  },
}))(DialogContentText);
