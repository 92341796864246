import { Link } from '@components/ui';
import { styled } from '@material-ui/core';
import { calcSizeBasedInVhScale } from '@utils/metrics';

export const Container = styled('div')({
  marginTop: calcSizeBasedInVhScale(20),
});

export const RegulamentationLink = styled(Link)({
  textDecoration: 'none',
  fontFamily: 'Barlow',
  textAlign: 'right',
  fontSize: calcSizeBasedInVhScale(16),
  color: '#007ef5',
  position: 'absolute',
});

export const ContainerCrown = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `${calcSizeBasedInVhScale(10)} 0`,
  height: calcSizeBasedInVhScale(150),

  '& svg': {
    fill: '#007ef5',
    width: calcSizeBasedInVhScale(75),
    height: calcSizeBasedInVhScale(65),
    minWidth: calcSizeBasedInVhScale(75),
    minHeight: calcSizeBasedInVhScale(65),
  },

  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    '& svg': {
      width: '35px',
      height: '40px',
      minWidth: '35px',
      minHeight: '40px',
    },
  },
}));

export const LabelNivel = styled('p')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(25),
  fontWeight: 'bold',
  paddingLeft: `${calcSizeBasedInVhScale(5)} !important`,
  paddingRight: `${calcSizeBasedInVhScale(20)} !important`,
  [theme.breakpoints.down('sm')]: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
}));
