const Home: React.FC = () => {
  return (
    <svg width="30.076" height="30.076" viewBox="0 0 30.076 30.076">
      <g transform="translate(3.133 2.507)">
        <path
          className="a"
          fill="#acb1c0"
          d="M19.149,25.061H16.655a2.284,2.284,0,0,1-2.243-2.117l-.012-.176V19.185a.8.8,0,0,0-.677-.778l-.113-.012H10.262a.794.794,0,0,0-.776.677l-.012.114v3.572a1.326,1.326,0,0,1-.025.224l-.012.026-.015.087A2.271,2.271,0,0,1,7.306,25.05l-.164.011H4.9A4.9,4.9,0,0,1,0,20.424V10.012A3.73,3.73,0,0,1,1.253,7.266L9.06.988A4.553,4.553,0,0,1,14.523.837l.2.151,7.63,6.1A3.773,3.773,0,0,1,23.8,9.759l.012.253V20.174A4.907,4.907,0,0,1,19.149,25.061Zm-9.074-8.409H13.61A2.551,2.551,0,0,1,16.141,19v3.772a.546.546,0,0,0,.439.538h2.33a3.141,3.141,0,0,0,3.144-2.932l.015-.2V10.023a2.031,2.031,0,0,0-.627-1.415l-.162-.139L13.635,2.354a2.792,2.792,0,0,0-1.743-.606,2.828,2.828,0,0,0-1.565.468l-.189.139L2.517,8.5a1.946,1.946,0,0,0-.762,1.327l-.015.2V20.174A3.146,3.146,0,0,0,4.7,23.306H7.143a.537.537,0,0,0,.55-.449l.026-.226.014-.064V19.185A2.521,2.521,0,0,1,10.075,16.652Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export default Home;
