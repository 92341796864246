import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'IBM Plex Sans',
    h1: {
      fontFamily: 'Barlow',
    },
    h2: {
      fontFamily: 'Barlow',
    },
    h3: {
      fontFamily: 'Barlow',
    },
    h4: {
      fontFamily: 'Barlow',
    },
    h5: {
      fontFamily: 'Barlow',
    },
  },
  palette: {
    primary: {
      main: '#0080ED',
    },
    secondary: {
      main: '#ADB1BF',
    },
  },
});

export default theme;
