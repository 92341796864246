import MuiDialog from '@material-ui/core/Dialog';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Container = styled('div')(() => ({
  width: '100%',
  padding: '40px 20px',
  borderRadius: 0,
  background: '#efefed',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',

  '@media screen and (min-width: 1100px)': {
    padding: '30px 40px',
    width: 722,
    maxHeight: 420,
  },
}));

export const Header = styled('div')(() => ({
  width: '100%',
  maxWidth: 265,
  margin: 'auto',
  padding: '24px 0 16px',

  '@media screen and (min-width: 1100px)': {
    padding: '0 0 24px',
    maxWidth: 'initial',
    marginBottom: 16,
    borderBottom: '2px solid #f5f5f5',
  },
}));

export const Title = styled('h2')(() => ({
  fontSize: 25,
  fontWeight: 700,
  margin: 0,
  fontFamily: 'Barlow, sans-serif',

  '@media screen and (min-width: 1100px)': {
    fontSize: 40,
  },
}));

export const Body = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '@media screen and (min-width: 1100px)': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const Content = styled('div')(() => ({
  width: '100%',
  maxWidth: 265,
  margin: 'auto',

  '@media screen and (min-width: 1100px)': {
    width: '48%',
    maxWidth: 'initial',
    position: 'relative',
  },

  '& h3': {
    fontSize: 18,
    fontWeight: 400,
    margin: 0,
    paddingBottom: 13,
  },
}));

export const Border = styled('div')(() => ({
  display: 'none',

  '@media screen and (min-width: 1100px)': {
    display: 'block',
    position: 'absolute',
    top: '10%',
    right: 0,
    width: 2,
    height: 185,
    background: '#fff',
  },
}));

export const ListItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '& img': {
    width: 18,
    height: 18,
    marginRight: 20,
  },

  '& p': {
    fontSize: 18,
    lineHeight: 1.8,
    fontWeight: 400,
    margin: 0,
  },
}));

export const Login = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '@media screen and (min-width: 1100px)': {
    width: '48%',
  },

  '& h4': {
    fontSize: 18,
    lineHeight: 1.8,
    fontWeight: 400,
    maxWidth: 265,

    '@media screen and (min-width: 1100px)': {
      textAlign: 'center',
      margin: 'auto',
    },

    '& span': {
      fontWeight: 700,
    },
  },
}));

export const SignupButton = withStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 265,
    height: 42,
    background: '#0080ed',
    margin: '8px auto 24px',
    borderRadius: 50,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 18,
    fontWeight: 700,

    '@media screen and (min-width: 1100px)': {
      margin: '24px auto',
    },
  },
}))(ButtonBase);

export const LoginButton = styled('p')(() => ({
  fontSize: 18,
  fontWeight: 400,
  margin: 0,

  '& a': {
    color: '#0080ed',
    textDecoration: 'none',
  },
}));

export const Dialog = withStyles(() => ({
  paper: {
    width: '100%',
    maxWidth: 722,
    borderRadius: '20px',
    border: '2px solid #fff',
    position: 'relative',
    overflowX: 'hidden',

    '@media screen and (min-width: 1100px)': {
      maxHeight: 420,
    },
  },
}))(MuiDialog);

export const CloseButton = withStyles(() => ({
  root: {
    position: 'absolute',
    top: '2%',
    right: '2%',
    fontWeight: 700,
    zIndex: 1,

    '@media screen and (min-width: 1100px)': {
      top: '9%',
      right: '3%',
    },
  },
}))(IconButton);
