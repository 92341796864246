/* eslint-disable no-param-reassign */
import { getChannel } from '@utils/videoDetect';
import VimeoPlayer from './Vimeo';
import SambaPlayer from './Samba';

export interface PlayerProps {
  vimeoId: string;
  videoUrl: string;
  volume?: number;
  togglePlayer?: boolean;
  getVolume?: (volume: number) => void;
  currentTime?: number;
  onPlay?: (event: Plyr.PlyrEvent) => any;
  onEnded?: (event: Plyr.PlyrEvent) => void;
  OnVolumeChange?: (event: Plyr.PlyrEvent) => void;
  getDuration?: (duration: number) => void;
  onDurationChange?: (duration: number) => void;
  getTitle?: (title: string) => void;
  autoplay?: boolean;
}

export interface PlayerRefProps {
  handleFullscreen(fullscreen: boolean): void;
}

const Player: React.FC<PlayerProps> = props => {
  const { videoUrl } = props;

  if (videoUrl) {
    const channel = getChannel(videoUrl);

    const players = {
      vimeo: <VimeoPlayer {...props} />,
      sambatech: <SambaPlayer {...props} />,
    };
    if (channel) return players[channel];
  }

  return null;
};

export default Player;
