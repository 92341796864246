import { ICardProps } from '@components/core/CardContent';
import { ICardPlaylistProps } from '@components/core/CardContent/Variants/PodcastPlaylists';
import { IFavoriteContextData } from '@hooks/favorite';
import {
  IContent,
  IContentList,
  IRecommendationsList,
  IRecommendationsContent,
} from '@models/content.interface';
import { IStory } from '@models/story.interface';
import { ContentType } from '@utils/contentType';
import dayjs from 'dayjs';

export const getVimeoIdByUrl = (url: string): string => {
  if (!url || url.trim() === '') {
    return '';
  }
  return url.includes('vimeo.com') ? url.split('.com/')[1] : url;
};

export const ratingFormat = (value: number): string => {
  if (!value) return '';
  const rounded = Math.round(value * 10) / 10;
  return rounded.toString().replace('.', ',');
};

export const getMaxText = (text: string, max = 100): string => {
  if (text) {
    return text.substring(0, max).trim() + (text.length <= max ? '' : '...');
  }
  return '';
};

export const getDateStringByMilliseconds = (
  durationInMilliseconds: number,
): string => {
  const date = dayjs()
    .startOf('day')
    .add(durationInMilliseconds, 'millisecond');

  const hh = date.hour() || 0;
  const mm = date.minute() || 0;
  const ss = date.second() || 0;

  let strDate = '--';
  if (hh) {
    strDate = mm ? `${hh}h ${mm}min` : `${hh}h`;
  } else if (mm) {
    strDate = ss ? `${mm}min ${ss}s` : `${mm}min`;
  } else if (ss) {
    strDate = `${ss}s`;
  }
  return strDate;
};

export const formatStories = (stories: IStory): ICardProps[] => {
  return stories?.contents?.reduce((acc, cur) => {
    switch (cur?.__component) {
      case 'content.article':
        acc.push({
          title: cur?.title,
          thumbnail:
            cur?.article?.feature_image?.formats?.thumbnail?.url ||
            cur?.article?.feature_image?.url ||
            null,
          background:
            cur?.article?.feature_image?.formats?.medium?.url ||
            cur?.article?.feature_image?.url ||
            null,
          url: `/artigos/${cur?.article?.slug}`,
          time: `${cur?.article?.read_time} min`,
          type: 'article',
          categories: cur?.article?.categories || [],
          id: cur?.article?._id || null,
          store_product: cur?.article?.store_product || { id: null, name: '' },
          slug: cur?.article?.slug || '',
        });
        break;

      default:
        break;
    }
    return acc;
  }, []);
};

export const formatContent = (contents: IContent[]): IContentList[] => {
  return (contents || []).reduce((acc, cur) => {
    const videos = cur?.videos.map(video => ({
      title: video?.title || '',
      thumbnail: video?.thumbnailUrl || null,
      background: video?.thumbnailUrl || null,
      url: `/aulas/${cur?.slug}/${video?.slug}`,
      time: getDateStringByMilliseconds(video?.videoTime),
      type: 'video',
      category: null,
      rating: video?.rating || null,
      id: video?.id,
    }));

    const articles = cur?.articles.map(article => ({
      title: article?.title,
      thumbnail: article?.feature_image?.formats?.thumbnail?.url || null,
      background:
        article?.feature_image?.formats?.small?.url ||
        article?.feature_image?.formats?.medium?.url ||
        article?.feature_image?.formats?.large?.url ||
        article?.feature_image?.url ||
        null,
      url: `/artigos/${article?.slug}`,
      time: `${article?.read_time || 10} min`,
      type: 'article',
      categories: article?.categories || [],
      id: article?.id,
      rating: article?.rating || null,
    }));

    const max =
      articles.length > videos.length ? articles.length : videos.length;
    const concat = [];
    for (let i = 0; i < max; i += 1) {
      if (articles.length > i) concat.push(articles[i]);
      if (videos.length > i) concat.push(videos[i]);
    }

    acc.push({
      name: cur.name,
      slug: cur.slug,
      stories: [...concat],
    });

    return acc;
  }, []);
};

export const formatFavorite = (
  favorites: IFavoriteContextData,
): IContentList[] => {
  return favorites.favorites?.map(favorite => {
    let content: IContent = {
      id: favorite.contentId,
      title: favorite.title || '',
      thumbnail: favorite.thumbnail || null,
      background: favorite.thumbnail || null,
      type: favorite.type,
      rating: favorite.rating || null,
      name: favorite.title || '',
      slug: favorite.slug || '',
      time: favorite.read_time || null,
      stories: '' || null,
      url: '' || null,
    };

    if (favorite.type === 'video') {
      content = {
        ...content,
        url: `/aulas/${favorite.mainMacrotag}/${favorite.slug}`,
        time: getDateStringByMilliseconds(favorite?.videoTime),
        category: null,
      };
    } else if (favorite.type === 'article') {
      content = {
        ...content,
        url: `/artigos/${favorite.slug}`,
        time: `${favorite?.read_time || 10} min`,
        category: null,
      };
    } else if (favorite.type === ContentType.podcastEpisode) {
      content = {
        ...content,
        url: `/podcasts/${favorite.podcastSlug}`,
        time: getDateStringByMilliseconds(favorite?.duration * 1000),
      };
    }

    return content;
  });
};

export const formatPlaylistFavorite = (
  favorites: IFavoriteContextData,
): ICardPlaylistProps[] => {
  return favorites?.favorites?.map(favorite => ({
    id: favorite?.contentId,
    image: favorite?.thumbnail,
    slug: favorite?.slug,
    title: favorite?.title,
    description: favorite?.description,
    episodes: favorite?.episodes?.map(episode => ({
      authorSlug: favorite?.slug,
      trackSlug: episode?.slug,
      id: episode.id,
      audioUrl: episode?.audioUrl,
      title: episode?.title,
      imageUrl: episode?.image?.url,
      author: favorite?.title,
      duration: episode?.duration,
    })),
  }));
};

export const formatRecommendations = (
  recommendations: IRecommendationsContent[],
): IRecommendationsList[] => {
  return recommendations.map((rec): IRecommendationsList => {
    return {
      section: rec.section,
      contents: rec.contents.map((content): ICardProps => {
        if (content.type === 'article') {
          return {
            title: content.content?.title,
            background:
              content.content?.feature_image?.formats?.small?.url ||
              content.content?.feature_image?.formats?.medium?.url ||
              content.content?.feature_image?.formats?.large?.url ||
              content.content?.feature_image?.url ||
              null,
            url: `/artigos/${content.content?.slug}`,
            time: `${content.content?.read_time || 10} min`,
            type: ContentType.article,
            id: content.content?.id,
            rating: content.content?.rating || null,
            slug: content.slug,
          };
        }
        if (content.type === 'video') {
          return {
            title: content.content?.title,
            background: content.content?.thumbnailUrl,
            url: `/aulas/${content.content?.slug}/${content.content?.slug}`,
            time: getDateStringByMilliseconds(content.content?.videoTime),
            type: ContentType.video,
            rating: content.content?.rating || null,
            id: content.content?.id,
            slug: content.slug,
          };
        }
      }),
    };
  });
};

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export const splitName = (name: string): string[] => {
  const parts = name.split(' ');

  if (parts.length < 2) return [name, '-'];

  return [parts.shift(), parts.join(' ')];
};
