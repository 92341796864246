import { styled, Button as MuiButton } from '@material-ui/core';
import { calcSizeBasedInVhScale } from '@utils/metrics';
import Ripples from 'react-ripples';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    margin: '20px 0',
    padding: 0,
  },
}));

export const Description = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(20),
  fontWeight: 500,
  marginBottom: `${calcSizeBasedInVhScale(10)} !important`,

  '& span': {
    fontSize: calcSizeBasedInVhScale(14),
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '10px !important',
    marginBottom: '0 !important',

    textAlign: 'center',
    fontSize: '14px !important',
    '& span': {
      fontSize: '14px !important',
    },
  },
}));

export const Button = styled(MuiButton)(({ theme }) => ({
  '&.MuiButton-contained': {
    backgroundColor: '#007EF5',
    boxShadow: 'none',
  },
  '& span.MuiButton-startIcon': {
    marginLeft: '0',
    marginRight: '0',
    '& svg': {
      fill: '#fff',
      width: calcSizeBasedInVhScale(20),
      height: calcSizeBasedInVhScale(20),
    },
  },
  '&.MuiButton-root': {
    padding: `${calcSizeBasedInVhScale(6)} ${calcSizeBasedInVhScale(13)}`,
    minWidth: `${calcSizeBasedInVhScale(65)} !important`,
  },

  [theme.breakpoints.down('sm')]: {
    '&.MuiButton-root': {
      padding: '6px 13px',
      minWidth: '45px !important',
    },
    '& span.MuiButton-startIcon': {
      '& svg': {
        maxWidth: '10px',
        maxHeight: '10px',
      },
    },
  },
}));

export const ContainerButtons = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const ContainerLink = styled(Ripples)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  borderRadius: '7px',
  border: '1px solid #BCBCBC',
  backgroundColor: '#fff',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: calcSizeBasedInVhScale(10),

  '& span': {
    marginLeft: calcSizeBasedInVhScale(12),
    fontFamily: 'Barlow',
    fontSize: calcSizeBasedInVhScale(16),
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: calcSizeBasedInVhScale(350),
  },

  [theme.breakpoints.down('sm')]: {
    margin: '10px 0',
    '& span': {
      fontSize: '12px !important',
      maxWidth: '60vw !important',
    },
  },
}));

export const CopyButton = styled(MuiButton)(({ theme }) => ({
  '&.MuiButton-contained': {
    backgroundColor: '#007EF5',
    boxShadow: 'none',
    height: '100%',
    width: calcSizeBasedInVhScale(100),
    borderRadius: '0 7px 7px 0',
  },
  '& span.MuiButton-startIcon': {
    marginLeft: `-${calcSizeBasedInVhScale(7)}`,
    marginRight: '0',
    '& svg': {
      fill: '#fff',
      width: calcSizeBasedInVhScale(30),
      height: calcSizeBasedInVhScale(35),
    },
  },
  '& span.MuiTouchRipple-root': {
    opacity: 0,
  },

  [theme.breakpoints.down('sm')]: {
    '&.MuiButton-contained': {
      width: '50px !important',
    },
    '& span.MuiButton-startIcon': {
      '& svg': {
        maxWidth: '20px!important',
        maxHeight: '20px!important',
      },
    },
  },
}));

export const ContainerShare = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
