export const PAINS = [
  {
    name: 'Cultura, Diversidade & Gestão de Pessoas',
    slug: 'cultura-diversidade-and-pessoas',
  },
  {
    name: 'Novas tecnologias e disrupções',
    slug: 'novas-tecnologias-e-disrupcoes',
  },
  {
    name: 'Novos Modelos de gestão ágeis',
    slug: 'novos-modelos-de-gestao-ageis',
  },
  {
    name: 'Estratégias de Vendas e Marketing para crescimento de negócios',
    slug: 'estrategias-de-vendas-e-marketing',
  },
  {
    name: 'Inovação',
    slug: 'inovacao',
  },
  {
    name: 'Transformação digital',
    slug: 'transformacao-digital',
  },
  {
    name: 'Empreendedorismo',
    slug: 'empreendedorismo',
  },
  {
    name: 'Carreira e novas habilidades humanas',
    slug: 'carreira-and-novas-habilidades-humanas',
  },
];

export function getRandomPains(): typeof PAINS {
  const groupPains = [];
  for (let i = 0; i < PAINS.length; i += 3) {
    groupPains.push(PAINS.slice(i, i + 3));
  }
  const rand = Math.floor(Math.random() * groupPains.length);
  return groupPains[rand];
}

export function returnHubspotPropKey(prop: string): string {
  const keyNames = {
    companyName: 'nome_da_empresa',
    segment: 'segmento',
    jobtitle: 'cargo_from_list',
    department: 'departamento',
    country: 'country',
    state: 'estado',
    city: 'cidade',
    phone: 'telefone',
  };

  return keyNames[prop];
}
