import api from '../api';

export const get = async (query: string, size = 10, from = 0): Promise<any> => {
  const params = new URLSearchParams();
  params.append('query', String(query));
  params.append('size', String(size));
  params.append('from', String(from));
  const searchResponse = await api.get('search', { params });
  return searchResponse.data;
};
