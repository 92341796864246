import { memo } from 'react';

import { Box } from '@material-ui/core';

import Card, { ICardProps } from '../CardContent';

import { Container, Content } from './styles';

export interface SliderProps {
  contents: ICardProps[];
  title?: string;
  link?: any;
  variant?: 'horizontal' | 'vertical';
  onClick?(e: Event): void;
  onClickFavorite?: (contendId: string) => void;
  showModalConfirm?: boolean;
}

const Slider: React.FC<SliderProps> = ({
  contents,
  variant = 'vertical',
  onClickFavorite,
  showModalConfirm,
}) => {
  if (!contents || contents.length < 1) return null;

  return (
    <>
      <Container>
        <Content vertical horizontal nativeMobileScroll>
          {contents?.map(content => (
            <Card
              key={content.id}
              {...content}
              variant={variant}
              onClickFavorite={onClickFavorite}
              showModalConfirm={showModalConfirm}
            />
          ))}
          <Box minWidth="10px" height="1px" />
        </Content>
      </Container>
    </>
  );
};

export default memo(Slider);
