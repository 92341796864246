// import styled from 'styled-components';
import { styled } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

export const PromptContainer = styled(Container)(() => ({
  position: 'fixed',
  bottom: '16px',
  left: 0,
  right: 0,
  zIndex: 999,
  '@media screen and (min-width: 1100px)': {
    left: 'initial',
    maxWidth: '400px',
  },
}));

export const PromptPaper = styled(Paper)(() => ({
  display: 'flex',
  borderRadius: '10px',
}));

export const Wrapper = styled('div')(() => ({
  width: '100%',
  margin: '12px',
  '@media screen and (min-width: 1100px)': {
    margin: '16px',
  },
}));

export const Title = styled('p')(() => ({
  marginBottom: '30px',
  marginTop: 0,
}));

export const WrapperChild = styled('div')(() => ({
  float: 'right',
}));
