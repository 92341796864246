import { memo, useState, useCallback } from 'react';

import SwiperCore, { Navigation, Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  Container,
  NavigationButton,
  ContainerPagination,
  Receptacle,
} from './styles';
import { StoriesProps } from '../..';
import Header from '../../Header';
import Content from '../../Content';

SwiperCore.use([Navigation, Pagination, Virtual]);

const Desktop: React.FC<StoriesProps> = ({
  handleClose,
  stories,
  initialSlide,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSelected = useCallback(
    (index: number) => {
      if (index === currentIndex) {
        return {
          height: '100%',
          maxHeight: '700px',
          opacity: '1',
        };
      }
      return {
        height: '80%',
        maxHeight: '640px',
        opacity: '0.2',
      };
    },
    [currentIndex],
  );

  return (
    <Container>
      <Header handleClose={handleClose} />
      <Swiper
        virtual
        centeredSlides
        initialSlide={initialSlide}
        onSlideChange={a => {
          setCurrentIndex(a.activeIndex);
        }}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },

          1024: {
            slidesPerView: 3,
          },
          1920: {
            slidesPerView: 5,
          },
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
      >
        {stories.map((story, index) => (
          <SwiperSlide virtualIndex={index} key={story?.title}>
            <Receptacle style={handleSelected(index)}>
              <Content story={story} handleClose={handleClose} />
            </Receptacle>
          </SwiperSlide>
        ))}
        <NavigationButton className="swiper-button-next" />
        <NavigationButton className="swiper-button-prev" />
      </Swiper>
      <ContainerPagination>
        <div className="swiper-pagination" />
      </ContainerPagination>
    </Container>
  );
};

export default memo(Desktop);
