import { IFavoriteContextData } from '@hooks/favorite';
import IFavorite from '@models/favorite.interface';
import api from '../api';

export const findAll = async (
  userId: string,
  limit = 10,
  offset = 0,
  type = '',
): Promise<IFavoriteContextData> => {
  const params = new URLSearchParams();
  params.append('limit', String(limit));
  params.append('offset', String(offset));
  params.append('userId', String(userId));
  if (type !== '') {
    params.append('type', type);
  }
  try {
    const favoritesResponse = await api.get<IFavoriteContextData>('favorites', {
      params,
    });
    return favoritesResponse.data;
  } catch {
    throw new Error('Failed to get user favorites');
  }
};

export const create = async (
  userId: string,
  contentId: string,
  type: string,
): Promise<IFavorite> => {
  try {
    const favoritesResponse = await api.post<IFavorite>('favorites', {
      userId,
      contentId,
      type,
    });
    return favoritesResponse.data;
  } catch {
    throw new Error('Failed to create user favorites');
  }
};

export const remove = async (favoriteId: string): Promise<IFavorite[]> => {
  try {
    const favoritesResponse = await api.delete(`favorites/${favoriteId}`);
    return favoritesResponse.data;
  } catch {
    throw new Error('Failed to delete user favorites');
  }
};
