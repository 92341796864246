const TicketIcon: React.FC = () => {
  return (
    <svg width="26.076" height="26.076" viewBox="0 0 26.076 26.076">
      <g transform="translate(1.086 3.259)">
        <path
          className="a"
          fill="#acb1c0"
          d="M4.5,19.557A4.494,4.494,0,0,1,0,15.079v-2.9a.837.837,0,0,1,.834-.838A1.578,1.578,0,0,0,2.42,9.779,1.5,1.5,0,0,0,.834,8.309L.721,8.3A.84.84,0,0,1,0,7.47V4.478A4.494,4.494,0,0,1,4.5,0H19.4a4.494,4.494,0,0,1,4.5,4.478v2.9a.835.835,0,0,1-.833.838,1.565,1.565,0,1,0,0,3.13.836.836,0,0,1,.833.838v2.9a4.494,4.494,0,0,1-4.5,4.478Zm14.9-1.676a2.821,2.821,0,0,0,2.833-2.8V12.912l-.091-.024a3.242,3.242,0,0,1-2.323-2.917l-.006-.192a3.219,3.219,0,0,1,2.329-3.11l.09-.023V4.478a2.821,2.821,0,0,0-2.646-2.8L19.4,1.676h-4.56V3.822a.834.834,0,0,1-1.66.113l-.007-.113V1.676H4.5a2.821,2.821,0,0,0-2.832,2.8l0,2.255.095.023A3.105,3.105,0,0,1,4.083,9.59l0,.19a3.217,3.217,0,0,1-2.329,3.109l-.091.022v2.169a2.821,2.821,0,0,0,2.646,2.8l.187.006h8.674V16.216a.833.833,0,0,1,1.659-.113l.008.113v1.665ZM13.181,12.49l-.007-.113V6.989a.833.833,0,0,1,1.659-.113l.008.113v5.388a.834.834,0,0,1-1.66.113Z"
        />
      </g>
    </svg>
  );
};

export default TicketIcon;
