import { memo, useState, useRef, useEffect, useCallback } from 'react';
import dynamic from 'next/dynamic';

import { Box } from '@material-ui/core';

import { HiddenSm } from '@components/ui';
import { useTracking } from '@hooks/tracking';
import PodcastPlaylists, {
  ICardPlaylistProps,
} from '../CardContent/Variants/PodcastPlaylists';

import {
  Container,
  ContainerHeader,
  Content,
  Title,
  LinkGroup,
  ContainerBody,
} from './styles';

const Arrows = dynamic(() => import('./ArrowsControl'));

export interface SliderProps {
  contents: ICardPlaylistProps[];
  title?: string;
  onClick?(e: Event): void;
  onClickFavorite?: (contendId: string) => void;
  showModalConfirm?: boolean;
  className?: string;
  description?: string;
  urlImageSeason?: string;
  customArrowCLick?: (event: 'next' | 'prev') => void;
  slug?: string;
  fontBold?: boolean;
}

const Slider: React.FC<SliderProps> = ({
  contents,
  title,
  onClick,
  showModalConfirm,
  customArrowCLick,
}) => {
  const perPage = 4;
  const refContent = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [page, setPage] = useState(1);
  const [contentsPage, setContentsPage] = useState(contents?.slice(0, 1));
  const [countScroll, setCountScroll] = useState(0);
  const { track } = useTracking();

  useEffect(() => {
    if (refContainer.current && contents?.length > 0) {
      setContentsPage(contents.slice(0, Math.round(containerWidth / 140 + 1)));
    }
  }, [contents, containerWidth]);

  const handleClientWidth = useCallback(() => {
    setContainerWidth(refContainer.current?.clientWidth);
  }, []);

  useEffect(() => {
    handleClientWidth();
    window.addEventListener('resize', handleClientWidth, {
      passive: true,
    });
    return () => {
      window.removeEventListener('resize', handleClientWidth);
    };
    // eslint-disable-next-line
  }, []);

  if (!contents || contents.length < 1) return null;
  const handleScroll = (event: 'next' | 'prev') => {
    if (refContent.current && refContainer.current) {
      const { scrollLeft } = refContent.current;
      const clientWidth = refContainer.current.clientWidth - 100;
      const valueScroll = event === 'next' ? clientWidth : -clientWidth;

      refContent.current.scrollTo({
        left: scrollLeft + valueScroll,
        behavior: 'smooth',
      });
    }
  };

  const handleNextPage = () => {
    if (contentsPage.length < contents.length) {
      setPage(page + 1);
      setContentsPage(contents.slice(0, (page + 1) * perPage));
    }
  };

  return (
    <>
      <Container ref={refContainer} isHomeWebSerieFeatured>
        <ContainerHeader>
          {title && (
            <Title
              onClick={() => {
                if (customArrowCLick) {
                  customArrowCLick('next');
                  if (countScroll === 0) {
                    setCountScroll(countScroll + 1);
                  }
                }
              }}
            >
              <>{title}</>
            </Title>
          )}

          <LinkGroup>
            <HiddenSm>
              <Arrows
                onPressNext={() => {
                  if (customArrowCLick) {
                    setCountScroll(countScroll + 1);
                    customArrowCLick('next');
                    if (countScroll > 0) {
                      handleScroll('next');
                    }
                  } else {
                    handleScroll('next');
                  }
                  track('Click on the button next - podcast', {});
                }}
                onPressPrev={() => {
                  if (customArrowCLick) {
                    setCountScroll(0);
                    customArrowCLick('prev');
                    handleScroll('prev');
                  } else {
                    handleScroll('prev');
                  }
                }}
              />
            </HiddenSm>
          </LinkGroup>
        </ContainerHeader>
        <ContainerBody>
          <Content
            hideScrollbars
            innerRef={refContent}
            onClick={onClick ? e => onClick(e) : null}
            onScroll={() => handleNextPage()}
            nativeMobileScroll
          >
            {contentsPage?.map(content => (
              <PodcastPlaylists
                key={content.id}
                {...content}
                showModalConfirm={showModalConfirm}
              />
            ))}
            <Box minWidth="10px" height="1px" />
          </Content>
        </ContainerBody>
      </Container>
    </>
  );
};

export default memo(Slider);
