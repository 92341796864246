import { usePrompt } from '@hooks/prompt';
import Button from '@material-ui/core/Button';
import { isMobile } from 'react-device-detect';
import {
  PromptContainer,
  PromptPaper,
  Wrapper,
  Title,
  WrapperChild,
} from './style';

const Prompt: React.FC = () => {
  const { hidePrompt, notShowPrompt, deferredEvt } = usePrompt();
  let title = 'Gostaria de adicionar o app da StartSe ao seu desktop?';

  if (isMobile) {
    title =
      'Gostaria de adicionar o app da StartSe na tela inicial do seu celular?';
  }

  if (notShowPrompt) {
    return null;
  }
  return (
    <PromptContainer>
      <PromptPaper elevation={3}>
        <Wrapper>
          <Title>{title}</Title>
          <WrapperChild>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                deferredEvt.prompt();
              }}
            >
              Sim
            </Button>
            <Button
              onClick={() => {
                hidePrompt();
              }}
            >
              Não
            </Button>
          </WrapperChild>
        </Wrapper>
      </PromptPaper>
    </PromptContainer>
  );
};

export default Prompt;
