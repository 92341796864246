import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const CopyLinkIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    className="startse-icon"
    viewBox="0 0 16 16"
    fontSize="small"
    {...props}
  >
    <image
      width="100%"
      height="100%"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHKADAAQAAAABAAAAHAAAAABkvfSiAAAEIUlEQVRIDa1WaUhUURQeN8x9wX0Gt7HJckCnabCMJKw/QkqpiPpDBRURHRQzEBVSQQz/GCiJS/bDH6IELj8ERTGR3HGUQiF1nBYrIqxccqF4fefR09ebsRlrHlzuved855x77zvnu1ckMuPn6ekZUV5eHtfR0ZHo7Owc2d3dbWVG98eu6urqfAoLCx+5uLh8tLKyYmxsbBj028nJyU9zcnKijpFmGM3Pz4f7+/tr4YrhmoWFxdGYZI2NjXlmCCUSjY2NRTk4OByQUzs7u93m5ua7cXFxF8PCwsJbWlpSlErlS24RCHrrv4KWlpaqEOwnOZRKpbq5ublQoUPI7AMCAsYJ4+vr+2FlZUUqxJg89/Pzu03/C/2rzMzMwJMMFxYWxEigLegZ4GpOwpkkb21tvVZSUuJlDBwfH99HAXHso8awZtGnp6c/poBo85an8SiRSMT5+fmRo6OjNx0dHc8zDGNhir1Wq5UTDvbvTcGL6uvrfbOysp64u7t/h8FRykdHR2uSkpLS/+akra3thqWlJWtTVlam/huW1c3OziqCg4OP6gz/4Seyc4cfuKCgoAEZaSN0Nj4+Hi4Wiz8TFoub0Gg0rkLMH/Pp6elLyLBtMnBzc/vU09OTlZ2dLfHy8vIGbSlzc3M7SEdteHhYxjfGQuXAffmt3wPdnePr9cYzMzMq7GaPDFxdXTc6OzsD9UAQJCQkqGtra++tr6+f4fRLS0tnfXx82J1BxvT398dwOoP91NTUZfDiVwLjSDZWV1dDDAINCAkLG0oO4tTDoaGhGwZgx6KJiYkorlDBErrl5eU/juoYqT+inYFX30DD2Nvb74L6ovVRPMlvbtwlAyTK69NQEWER7C3Zent7bxkNNjk5GWlra8sSMdJYt7i4KOHWgrqzLi4uvp+WllbJyfg9si8QC2SDOTk5HRQVFV3l6/XGdBR0BFAwyKz1kZERMR+Enx5EOmoqlaqvt7c3Co5loaGhsvb29mz8bzZBYHuYmpp6hW9rcIwaeU7OUNhvwSQBQhCOy7aiouIBYfiNf+/hSlrLy8tTCm315k1NTdc5J1j5TT0AT0B3WmxsbB/HHGSHXb7Dzf4AfoySOOsKNXSfDBUKhYbn29iQTkFKTa1WOxsD8/XWOp2OXZmHh8cLvsLI+DWnb2ho4IYm9dZIYaIu0c7OjtF6A1/6o05lm5ubzyorK3+YFEEIqqqqugMZQ88FsDl7jQgxNF9bW5MFBQVtEDYxMTHDEMYkGVZsB0csO4Az58DwbkJDyGQhISFsneFJsV1dXa0QYk41HxwcJHJlU14ul692dXVlgNZiUF8xNTU190AILImjzvZxlBdP5fwkMK6bJCTOPhdY2OPJpwWDRJxk/0/ygYGBC3hZPcTREtt/o4YdL6ekpFSAUTz/yanA6Bd8A530njjKjwAAAABJRU5ErkJggg=="
    />
  </SvgIcon>
);
export default CopyLinkIcon;
