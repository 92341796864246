const WebSerieIcon: React.FC = () => {
  return (
    <svg width="31.063" height="28.557" viewBox="0 0 31.063 28.557">
      <g transform="translate(3 3)">
        <g transform="translate(0.303 -0.95)">
          <path
            className="a"
            fill="#acb1c0"
            stroke="rgba(242,0,0,0)"
            d="M6.533,23.507a6.582,6.582,0,0,1-4.908-2.121A7.657,7.657,0,0,1-.3,16.165V8.293A7.654,7.654,0,0,1,1.625,3.072,6.582,6.582,0,0,1,6.533.95H17.9a6.962,6.962,0,0,1,6.85,7.342l0,7.816a7.045,7.045,0,0,1-1.85,5.177,6.966,6.966,0,0,1-5.008,2.222ZM1.6,8.293v7.872c0,3.145,2.076,5.427,4.936,5.427H17.877A5.047,5.047,0,0,0,21.5,19.979a5.131,5.131,0,0,0,1.342-3.814V8.236A5.118,5.118,0,0,0,21.5,4.477a5.047,5.047,0,0,0-3.628-1.613H6.533C3.674,2.865,1.6,5.147,1.6,8.293Zm8.058,8.083a3.865,3.865,0,0,1-.216-.735l-.063-.328a19.773,19.773,0,0,1-.178-2.94l0-.363a17.231,17.231,0,0,1,.2-2.691,6.081,6.081,0,0,1,.21-.849,1.467,1.467,0,0,1,1.335-.9h.047l.13.013a4.242,4.242,0,0,1,.837.254,17.794,17.794,0,0,1,4.761,3.213l.281.3.1.115a1.456,1.456,0,0,1,.031,1.76,2.762,2.762,0,0,1-.237.27l-.337.352a18.8,18.8,0,0,1-4.473,3A4.025,4.025,0,0,1,11,17.164h-.044A1.474,1.474,0,0,1,9.655,16.375Zm1.472-6.8-.042.249a19.386,19.386,0,0,0-.14,2.552l0,.348a16.883,16.883,0,0,0,.153,2.308l.061.294.24-.088a16.708,16.708,0,0,0,4.047-2.758l.1-.108-.266-.274a16.923,16.923,0,0,0-3.97-2.624l-.155-.056Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default WebSerieIcon;
