import { styled } from '@material-ui/core';
import { calcSizeBasedInVhScale } from '@utils/metrics';

export const ContainerProgress = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: calcSizeBasedInVhScale(20),

  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));

export const Label = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(16),
  marginTop: `${calcSizeBasedInVhScale(5)} !important`,
  textAlign: 'center',
  '& span': {
    fontWeight: 'bold',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}));
